//@typescript-eslint/no-unsafe-member-access
//@typescript-eslint/no-unsafe-assignment
import { fetchData, fetchDataFromFront } from "@gql/graphQLRequestServer";
import { DOMAIN_INFO } from "@gql/queries/domainInfo";
import {
    GQLDomainQuery,
    GQLDomainQueryVariables,
    GQLAllPostsQuery,
    GQLCategories,
    GQLAllCategoriesQuery,
    GQLAllDomainFormsQuery,
    GQLPosts,
    GQLAllPostsQueryVariables,
    GQLDomainForms,
    GQLCategoryPostsQuery,
    GQLOffers,
    GQLPostByIdQuery,
    GQLAllPostsSiteMapQuery,
    GQLAllPostsSiteMapQueryVariables,
    GQLCategoryBySlugPaginationQuery,
    GQLAllCategoriesCategorySlugQuery,
    GQLAllCategoriesCategorySlugQueryVariables,
    GQLTagBySlugQuery,
    GQLAllDomainFormsQueryVariables,
    GQLCategoryByIdQueryVariables,
    GQLAllCategoriesQueryVariables,
    GQLAllTagsQuery,
    GQLAllTagsQueryVariables,
    GQLTags,
    GQLFeaturedPostsHomePageQuery,
    GQLFeaturedPostsHomePageQueryVariables,
    GQLTagBySlugQueryVariables,
    GQLAllUrlsQuery,
    GQLAllUrlsQueryVariables,
    GQLRecentPostsByCategoryQuery,
    GQLRecentPostsByCategoryQueryVariables,
    GQLUrls,
    GQLLastArticlesPerBlogQuery,
    GQLLastArticlesPerBlogQueryVariables,
    GQLAllCategoriesSlugsQuery,
    GQLCrossSellingDomainFormQuery,
    GQLCrossSellingDomainFormQueryVariables,
    Maybe,
    GQLTextBlocksQuery,
    GQLTextBlocksQueryVariables,
    GQLGetCategoryByIdQuery,
    GQLGetCategoryByIdQueryVariables,
    GQLOfferByIdQuery,
    GQLOfferByIdQueryVariables,
    GQLDkis,
    GQLAllDkisQuery,
    GQLAllDkisQueryVariables,
    GQLGetOverviewDomainOffersQuery,
    GQLGetOverviewDomainOffersQueryVariables,
    GQLGetCategoriesPageDomainOffersQuery,
    GQLGetCategoriesPageDomainOffersQueryVariables,
    GQLGetTopVendorsQuery,
    GQLGetTopVendorsQueryVariables,
    GQLDomainOffers,
    GQLGetTextBlocksByPositionQuery,
    GQLGetTextBlocksByPositionQueryVariables,
    GQLCustomTextBlocksPositions,
    GQLCategoryBySlugPaginationQueryVariables,
    GQLComparisonMetaQuery,
    GQLComparisonMetaQueryVariables,
    GQLGetOffersByCategoryIdQuery,
    GQLGetOffersByCategoryIdQueryVariables,
    GQLTextBlocks,
    GQLCompareGetCategoryByIdQuery,
    GQLCompareGetCategoryByIdQueryVariables,
    GQLGetCompareDomainOffersQuery,
    GQLGetCompareDomainOffersQueryVariables,
    GQLArticlesGetCategoryByIdQuery,
    GQLArticlesGetCategoryByIdQueryVariables,
    GQLCategoryPostsByCategoryIdQuery,
    GQLGetArticlesDomainOffersQuery,
    GQLGetArticlesDomainOffersQueryVariables,
    GQLGetReviewDomainOffersQuery,
    GQLGetReviewDomainOffersQueryVariables,
    GQLVendorCompareGetDomainOffersQueryVariables,
    GQLVendorCompareGetDomainOffersQuery,
    GQLVendorCompareGetVendorsToCompareQuery,
    GQLVendorCompareGetVendorsToCompareQueryVariables,
    GQLPostGetCategoryByIdQuery,
    GQLPostGetCategoryByIdQueryVariables,
    GQLGetPostByIdQuery,
    GQLGetPostByIdQueryVariables,
    GQLCategoriesGetAllCategoriesQueryQuery,
    GQLCategoriesGetAllCategoriesQueryQueryVariables,
    GQLSitemapGetAllDomainCategoriesQueryVariables,
    GQLSitemapGetAllDomainCategoriesQuery,
    GQLSitemapGetAllDomainOffersQuery,
    GQLSitemapGetAllDomainOffersQueryVariables,
    GQLDomainCategories,
    GQLLookupsByIdsQuery,
    GQLLookupsByIdsQueryVariables,
    GQLCategoryAttributesValues,
    GQLGetExitModalOffersQuery,
    GQLGetExitModalOffersQueryVariables,
    GQLGetTop3BuyersQuery,
    GQLGetTop3BuyersQueryVariables,
    GQLCategoryOffersQueryQuery,
    GQLCategoryOffersQueryQueryVariables,
    GQLFeaturedNewsQueryQuery,
    GQLFeaturedNewsQueryQueryVariables,
    GQLCategoryPostsByCategoryIdQueryVariables,
    GQLTotalArticlesQuery,
    GQLTotalArticlesQueryVariables,
    GQLNewsByTagsQueryQuery,
    GQLNewsByTagsQueryQueryVariables,
    GQLTrendingNewsQuery,
    GQLTrendingNewsQueryVariables,
    GQLMissedNewsQuery,
    GQLMissedNewsQueryVariables,
    GQLTotalNewsByTagsQuery,
    GQLTotalNewsByTagsQueryVariables,
    GQLTagsOptionsQuery,
    GQLTagsOptionsQueryVariables,
    GQLAllNewsQuery,
    GQLAllNewsQueryVariables,
    GQLTotalAllNewsQuery,
    GQLTotalAllNewsQueryVariables,
    GQLTotalAllWritersPostsQuery,
    GQLTotalAllWritersPostsQueryVariables,
    GQLUserBySlugQuery,
    GQLUserBySlugQueryVariables,
    GQLUsers,
    GQLAllPostsWritersQuery,
    GQLAllPostsWritersQueryVariables,
} from "@gql/types/gql";
import {
    CategoriesList,
    IBestOverallPageProps,
    IBlogPostPageProps,
    ICategoriesPageProp,
    ICategoryArticlesPageProps,
    ICategoryByIdPageProps,
    ICategoryPageSharedProps,
    ICompareCategoryProps,
    IReviewOfferPageProps,
    ISitemapPageProps,
    IVendorComparePageProps,
    OpenSearchResult,
} from "@genericTypes/sharedTypes";
import { InfiniteData } from "@tanstack/react-query";
import { dehydrate, QueryClient } from "@tanstack/react-query";
import { ALL_CATEGORIES } from "@gql/queries/allCategories";
import { CATEGORY_WITH_FORMS } from "@gql/queries/categoriesThatHaveForm";
import { CATEGORY_BY_ID } from "@gql/queries/getFormByCategoryById";
import { TAG_BY_SLUG } from "@gql/queries/tagBySlug";
import { ALL_POSTS } from "@gql/queries/allPosts";
import { POST_BY_ID } from "@gql/queries/postById";
import { ALL_CATEGORIES_SLUGS } from "@gql/queries/allCategoriesSlugs";
import { ALL_POSTS_SITE_MAP } from "@gql/queries/allPostsSiteMap";
import {
    GetServerSidePropsContext,
    GetStaticPropsContext,
    NextApiRequest,
} from "next";
import { CATEGORY_BY_SLUG_PAGINATION } from "@gql/queries/categoryBySlugPagination";
import { ALL_CATEGORIES_CATEGORY_SLUG } from "@gql/queries/allCategoriesCategorySlug";
import { ALL_TAGS } from "@gql/queries/allTags";
import { FEATURED_POSTS_HOME_PAGE } from "@gql/queries/featuredPostsHomePage";
import { RECENT_POSTS_BY_CATEGORY } from "@gql/queries/recentPostsByCategory";
import { formatTranslation } from "./translationObjectFormatter";
import { ALL_URL } from "@gql/queries/allUrls";
import { CATEGORY_AND_OFFER_DROPDOWN } from "@gql/queries/categoryAndOfferDropdownQuery";
import { LAST_ARTICLES_PER_BLOG } from "@gql/queries/lastArticles";
import { CROSS_SELLING_DOMAIN_FORM } from "@gql/queries/crossSellingDomainFormQuery";
import { removeWordFromString } from "@helpers/removeWordFromString";
import { getSearchResult } from "src/api/search";
import { TEXT_BLOCKS_QUERY } from "@gql/queries/textBlocksQuery";
import { GET_CATEGORY_BY_ID } from "@gql/queries/categoryByIdQuery";
import { ALL_DKIS } from "@gql/queries/allDkis";
import { OFFER_BY_ID } from "@gql/queries/offerByIdQuery";
import { OVERVIEW_DOMAIN_OFFER_QUERY } from "@gql/queries/overviewPageDomainOffersQuery";
import { CATEGORIES_PAGE_DOMAIN_OFFERS_QUERY } from "@gql/queries/categoriesPageDomainOffersQuery";
import { HOME_PAGE_TOO_VENDORS_QUERY } from "@gql/queries/homePageTopVendorsSectionQuery";
import { GET_TEXT_BLOCKS_BY_POSITIONS } from "@gql/queries/getTextBlocksByPositionQuery";
import { COMPARISON_META } from "@gql/queries/comparisonMetaQuery";
import { CATEGORY_POSTS_BY_CATEGORY_ID } from "@gql/queries/categoryPostsByCategoryId";
import {
    resolveURL,
    findIntersection,
    getReplacedPredefinedListAttributes,
    PREDEFINED_TAGS,
} from "@lib/sharedUtils";
import { OVERVIEW_GET_CATEGORY_BY_ID } from "@gql/queries/pages/category/overview/getCategoryByIdQuery";
import { GET_OFFERS_BY_CATEGORY_ID } from "@gql/queries/pages/category/overview/getOfferByCategoryIdQuery";
import { OVERVIEW_GET_TEXT_BLOCKS_BY_POSITIONS } from "@gql/queries/pages/category/overview/getTextBlocksByPositionQuery";
import { GET_RECENT_POSTS_BY_CATEGORY_QUERY } from "@gql/queries/pages/category/overview/recentPostsByCategoryQuery";
import { BEST_OVERALL_DOMAIN_OFFER_QUERY } from "@gql/queries/pages/category/bestOverAll/bestOverallDomainOffersQuery";
import { BEST_OVERALL_GET_CATEGORY_BY_ID } from "@gql/queries/pages/category/bestOverAll/bestOverallGetCategoryQuery";
import { COMPARE_GET_CATEGORY_BY_ID } from "@gql/queries/pages/category/compare/compareGetCategoryByIdQuery";
import { COMPARE_DOMAIN_OFFER_QUERY } from "@gql/queries/pages/category/compare/compareGetDomainOffersQuery";
import { ARTICLES_GET_CATEGORY_BY_ID } from "@gql/queries/pages/category/articles/articlesGetCategoryByIdQuery";
import { ARTICLES_DOMAIN_OFFER_QUERY } from "@gql/queries/pages/category/articles/articlesGetDomainOffers";
import { REVIEW_GET_CATEGORY_BY_ID } from "@gql/queries/pages/vendor/review/reviewGetCategoryById";
import { REVIEW_GET_DOMAIN_OFFER_QUERY } from "@gql/queries/pages/vendor/review/reviewGetDomainOffersQuery";
import { VENDOR_COMPARE_GET_DOMAIN_OFFER_QUERY } from "@gql/queries/pages/vendor/compare/vendorComapreDomainOffersQuery";
import { VENDOR_COMPARE_GET_VENDORS_TO_COMPARE_QUERY } from "@gql/queries/pages/vendor/compare/vendorCompareOffersToCompareQuery";
import { POST_GET_CATEGORY_BY_ID } from "@gql/queries/pages/post/postGetCategoryByIdQuery";
import { POST_GET_POST_BY_ID } from "@gql/queries/pages/post/postGetPostByIdQuery";
import { CATEGORIES_GET_ALL_CATEGORIES } from "@gql/queries/pages/categories/categoriesGetAllCategoriesQuery";
import { DomainOffer } from "@components/shared/accordion/category-item";
import { SITEMAP_GET_ALL_DOMAIN_CATEGORIES } from "@gql/queries/pages/sitemap/sitemapGetDomainCategories";
import { SITEMAP_GET_ALL_DOMAIN_OFFERS } from "@gql/queries/pages/sitemap/sitemapGetDomainOffers";
import { DEFAULT_OPTIONS } from "@lib/reactQueryDefaultOptions";
import { LOOKUPS_BY_IDS } from "@gql/queries/lookupsByIds";
import { EXIT_MODAL_OFFERS_QUERY } from "@gql/queries/pages/shared/getExitModalOffersQuery";
import { TOP_3_BUYERS_QUERY } from "@gql/queries/pages/category/shared/top3BuyersQuery";
import { CATEGORY_OFFERS_QUERY } from "@gql/queries/pages/category/compare/categoryOffersQuery";
import { FEATURED_NEWS_QUERY } from "@gql/queries/pages/news/featuredNewsQuery";
import { TOTAL_ARTICLES } from "@gql/queries/pages/category/articles/totalArticlesQuery";
import { NEWS_BY_TAGS } from "@gql/queries/pages/news/newsByTagsQuery";
import { TRENDING_NEWS } from "@gql/queries/pages/news/trendingNewQuery";
import { MISSED_NEWS } from "@gql/queries/pages/news/missedNewQuery";
import { INewsByTags } from "@components/news/NewsByTags";
import { TOTAL_NEWS_BY_TAGS } from "@gql/queries/pages/news/totalNewsByTags";
import { TAGS_OPTIONS } from "@gql/queries/pages/news/tagsOptionsQuery";
import { ALL_NEWS } from "@gql/queries/pages/news/allNews";
import { ALL_POSTS_WRITERS } from "@gql/queries/pages/writers/allPostsWriters";
import { TOTAL_ALL_WRITERS_POSTS } from "@gql/queries/pages/writers/totalAllWritersPosts";
import { USER_BY_SLUG } from "@gql/queries/pages/writers/userBySlug";
import { TOTAL_ALL_NEWS } from "@gql/queries/pages/news/totalAllNewsQuery";

interface PostVars {
    id: number;
}
const locale = process.env.NEXT_PUBLIC_DEFAULT_LANG;

const BLOGS_PER_PAGE_ARTICLES = 9;
const POSTS_IS_PUBLISHED_ARTICLES = true;
const BLOGS_PER_PAGE_GENERAL = 9;
const LANGUAGES = ["en", "ar", "es", "ko", "zh"];

const allCategoriesQuery = async (
    queryClient: QueryClient,
    domainId: number,
    skipCache: boolean = false,
) => {
    return await queryClient.fetchQuery<GQLCategories[]>({
        queryKey: ["allCategories"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllCategoriesQuery,
                GQLAllCategoriesQueryVariables
            >(
                ALL_CATEGORIES,
                {
                    domainId: domainId,
                },
                skipCache,
            )();
            return data?.domain?.categories as GQLCategories[];
        },
    });
};
export const categoriesGetAllCategories = async (
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLCategoriesGetAllCategoriesQueryQuery,
        GQLCategoriesGetAllCategoriesQueryQueryVariables
    >(
        CATEGORIES_GET_ALL_CATEGORIES,
        {
            domainId: domainId,
        },
        skipCache,
    )();
    return data.domain?.categories as GQLCategories[];
};

// const categoriesForHomePageQuery = async (
//     queryClient: QueryClient,
//     domainId: number,
// ) => {
//     return await queryClient.fetchQuery<GQLCategories[]>({
//         queryKey: ["categoriesTab"],
//         queryFn: async () => {
//             const data = await fetchData<
//                 GQLAllCategoriesQuery,
//                 GQLAllCategoriesQueryVariables
//             >(CATEGORIES_FOR_HOMEPAGE, {
//                 domainId: domainId,
//             })();
//             return data?.domain?.categories as GQLCategories[];
//         },
//     });
// };

const categoriesAndOffersDropDownQuery = async (
    queryClient: QueryClient,
    domainId: number,
) => {
    return await queryClient.fetchQuery<GQLCategories[]>({
        queryKey: ["categoriesAndOfferDropdown"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllCategoriesQuery,
                GQLAllCategoriesQueryVariables
            >(CATEGORY_AND_OFFER_DROPDOWN, {
                domainId: domainId,
            })();
            return data?.domain?.categories as GQLCategories[];
        },
    });
};
export const categoriesPageDomainOffers = async (domainId: number) => {
    const data = await fetchData<
        GQLGetCategoriesPageDomainOffersQuery,
        GQLGetCategoriesPageDomainOffersQueryVariables
    >(CATEGORIES_PAGE_DOMAIN_OFFERS_QUERY, {
        domainId: domainId,
        language: locale as string,
    })();
    return data.allDomainOffers?.filter(
        (item) => item?.offers?.length,
    ) as DomainOffer[];
};
const homePageTopVendorsQuery = async (
    queryClient: QueryClient,
    domainId: number,
) => {
    const res = await queryClient.fetchQuery<GQLDomainOffers[]>({
        queryKey: ["homePageTopVendors"],
        queryFn: async () => {
            const data = await fetchData<
                GQLGetTopVendorsQuery,
                GQLGetTopVendorsQueryVariables
            >(HOME_PAGE_TOO_VENDORS_QUERY, {
                domainId: domainId,
                language: locale as string,
            })();
            return data.allDomainOffers?.filter(
                (item) => item?.offers?.length,
            ) as GQLDomainOffers[];
        },
    });
    queryClient.setQueryData(["homePageTopVendors"], res);
    return res;
};
// const topVendorsQuery = async (queryClient: QueryClient, domainId: number) => {
//     return await queryClient.fetchQuery<GQLCategories[]>({
//         queryKey: ["topVendors"],
//         queryFn: async () => {
//             const data = await fetchData<
//                 GQLAllCategoriesQuery,
//                 GQLAllCategoriesQueryVariables
//             >(TOP_VENDORS, {
//                 domainId: domainId,
//             })();
//             return data?.domain?.categories as GQLCategories[];
//         },
//     });
// };

const recentPostsQuery = async (
    queryClient: QueryClient,
    id: number,
    skipCache: boolean = false,
) => {
    await queryClient.fetchQuery<GQLPosts[]>({
        queryKey: ["recentPosts"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllPostsQuery,
                GQLAllPostsQueryVariables
            >(
                ALL_POSTS,
                {
                    offset: 0,
                    limit: 4,
                    published: true,

                    domainId: id,
                },
                skipCache,
            )();
            return data.allPosts as GQLPosts[];
        },
    });
    const recentPosts = queryClient.getQueryData(["recentPosts"]);

    const recentPostsFinal = (recentPosts as GQLPosts[]).map((el) => ({
        title: el.title,
        link: el.url?.url,
        id: el.id,
        content: el.excerpt ? el.excerpt : "",
        // content: el.excerpt ? el.excerpt : getFirstParagraphFormPost(el.body),
    }));

    queryClient.setQueryData(["recentPosts"], recentPostsFinal);
};
const lastArticlesQuery = async (queryClient: QueryClient, id: number) => {
    await queryClient.fetchQuery<GQLPosts[]>({
        queryKey: ["lastArticles"],
        queryFn: async () => {
            const data = await fetchData<
                GQLLastArticlesPerBlogQuery,
                GQLLastArticlesPerBlogQueryVariables
            >(LAST_ARTICLES_PER_BLOG, {
                domainId: id,
            })();
            return data.allPosts as GQLPosts[];
        },
    });
};

const featuredNewsQuery = async (id: number) => {
    const data = await fetchData<
        GQLFeaturedNewsQueryQuery,
        GQLFeaturedNewsQueryQueryVariables
    >(FEATURED_NEWS_QUERY, {
        domainId: id,
    })();
    return data.allPosts;
};
const tagsOptionsQuery = async (id: number, type = "post", typeId = 2296) => {
    const data = await fetchData<
        GQLTagsOptionsQuery,
        GQLTagsOptionsQueryVariables
    >(TAGS_OPTIONS, {
        domainId: id,
        type,
        typeId,
    })();
    return data.allTags
        ?.filter((item) => item?.posts?.length)
        .map((item) => ({
            id: item?.id,
            slug: item?.slug,
            label: item?.name,
            url: `/news/tag/${item?.slug ?? ""}`,
        }));
};

const newsByTagsQuery = async (id: number) => {
    const data = await fetchData<
        GQLNewsByTagsQueryQuery,
        GQLNewsByTagsQueryQueryVariables
    >(NEWS_BY_TAGS, {
        domainId: id,
    })();
    return data.allPosts?.reduce((res: INewsByTags, item) => {
        const tag = item?.tags?.find((val) =>
            PREDEFINED_TAGS.includes(val?.name?.toLocaleLowerCase() ?? ""),
        );
        if (
            tag &&
            tag.name &&
            (!res[tag.name.toLocaleLowerCase()] ||
                res[tag.name.toLocaleLowerCase()].length < 8)
        )
            // @ts-ignore
            res = {
                ...res,
                [tag.name.toLocaleLowerCase()]: [
                    ...(res[tag.name.toLocaleLowerCase()]
                        ? res[tag.name.toLocaleLowerCase()]
                        : []),
                    item,
                ],
            };
        return res;
    }, {});
};
const recentPostsByCategoryQuery = async (
    queryClient: QueryClient,
    domainId: number,
    categoryId: number,
    categorySlug: string,
    skipCache: boolean = false,
) => {
    // eslint-disable-next-line no-console
    await queryClient.fetchQuery<GQLPosts[]>({
        queryKey: ["recentPostsByCategory", categoryId],
        queryFn: async () => {
            const data = await fetchData<
                GQLRecentPostsByCategoryQuery,
                GQLRecentPostsByCategoryQueryVariables
            >(
                RECENT_POSTS_BY_CATEGORY,
                {
                    limit: 4,
                    offset: 0,
                    domainId: domainId,
                    published: true,
                    categoryId: categoryId,
                },
                skipCache,
            )();

            return data.allPosts as GQLPosts[];
        },
    });

    const recentPosts = queryClient.getQueryData([
        "recentPostsByCategory",
        categoryId,
    ]);

    const recentPostsFinal = (recentPosts as GQLPosts[]).map((post) => ({
        title: post.title,
        link: post.url?.url,
    }));

    queryClient.setQueryData(
        ["recentPostsByCategory", categoryId],
        recentPostsFinal,
    );
};
const getRecentPostsByCategoryQuery = async (
    id: number,
    categoryId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLRecentPostsByCategoryQuery,
        GQLRecentPostsByCategoryQueryVariables
    >(
        GET_RECENT_POSTS_BY_CATEGORY_QUERY,
        {
            offset: 0,
            limit: 4,
            published: true,
            categoryId: categoryId,
            domainId: id,
        },
        skipCache,
    )();

    return data.allPosts?.map((post) => ({
        title: post?.title,
        link: resolveURL(post?.url as GQLUrls),
    }));
};

const categoryByIdQuery = async (
    queryClient: QueryClient,
    categoryId: number,
    locale: String,
) => {
    return await queryClient.fetchQuery<GQLCategories>({
        queryKey: ["categoryById", categoryId.toString()],
        queryFn: async () => {
            const data = await fetchData<
                GQLGetCategoryByIdQuery,
                GQLGetCategoryByIdQueryVariables
            >(GET_CATEGORY_BY_ID, {
                id: categoryId,
                language: locale as string,
            })();

            return data?.category as GQLCategories;
        },
    });
};

const overviewGetCategoryByIdQuery = async (
    categoryId: number,
    locale: string,
) => {
    const data = await fetchData<
        GQLGetCategoryByIdQuery,
        GQLGetCategoryByIdQueryVariables
    >(OVERVIEW_GET_CATEGORY_BY_ID, {
        id: categoryId,
        language: locale,
    })();
    return data.category;
};
const reviewGetCategoryByIdQuery = async (
    categoryId: number,
    locale: string,
) => {
    const data = await fetchData<
        GQLGetCategoryByIdQuery,
        GQLGetCategoryByIdQueryVariables
    >(REVIEW_GET_CATEGORY_BY_ID, {
        id: categoryId,
        language: locale,
    })();
    return data.category;
};
const bestOverallGetCategoryByIdQuery = async (
    categoryId: number,
    locale: string,
) => {
    const data = await fetchData<
        GQLGetCategoryByIdQuery,
        GQLGetCategoryByIdQueryVariables
    >(BEST_OVERALL_GET_CATEGORY_BY_ID, {
        id: categoryId,
        language: locale,
    })();
    return data.category;
};
const articlesGetCategoryByIdQuery = async (
    categoryId: number,
    locale: string,
) => {
    const data = await fetchData<
        GQLArticlesGetCategoryByIdQuery,
        GQLArticlesGetCategoryByIdQueryVariables
    >(ARTICLES_GET_CATEGORY_BY_ID, {
        id: categoryId,
        language: locale,
    })();
    return data.category;
};

const getOffersByCategoryId = async (
    categoryId: number,
    domainId: number,
    locale: string,
) => {
    const data = await fetchData<
        GQLGetOffersByCategoryIdQuery,
        GQLGetOffersByCategoryIdQueryVariables
    >(GET_OFFERS_BY_CATEGORY_ID, {
        categoryId,
        domainId,
        language: locale,
    })();
    return data.allDomainOffers as GQLDomainOffers[];
};

const getTextBlocksByPositions = async (
    queryClient: QueryClient,
    categoryId: number,
    domainId: number,
    positions: string[],
) => {
    const data = await fetchData<
        GQLGetTextBlocksByPositionQuery,
        GQLGetTextBlocksByPositionQueryVariables
    >(GET_TEXT_BLOCKS_BY_POSITIONS, {
        id: categoryId,
        domainId: domainId,
        positions: positions,
    })();

    return data.allCustomTextBlocksPositions?.flatMap(
        (item) => item?.textBlocks,
    ) as GQLCustomTextBlocksPositions[];
};
const overviewGetTextBlocksByPositions = async (
    categoryId: number,
    domainId: number,
    positions: string[],
) => {
    const data = await fetchData<
        GQLGetTextBlocksByPositionQuery,
        GQLGetTextBlocksByPositionQueryVariables
    >(OVERVIEW_GET_TEXT_BLOCKS_BY_POSITIONS, {
        id: categoryId,
        domainId: domainId,
        positions: positions,
    })();
    return data.allCustomTextBlocksPositions?.flatMap(
        (item) => item?.textBlocks,
    ) as GQLCustomTextBlocksPositions[];
};

const postGetCategoryById = async (
    categoryId: number,
    language: string,
    domainId: number,
): Promise<GQLCategories> => {
    const data = await fetchData<
        GQLPostGetCategoryByIdQuery,
        GQLPostGetCategoryByIdQueryVariables
    >(POST_GET_CATEGORY_BY_ID, {
        id: categoryId,
        language: language,
        limit: 4,
        offset: 0,
        domainId: domainId,
        published: true,
    })();
    return data.category as GQLCategories;
};

const overviewPageDomainOffersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    slugs: string[],
    skipCache: boolean = false,
) => {
    const res = await fetchData<
        GQLGetOverviewDomainOffersQuery,
        GQLGetOverviewDomainOffersQueryVariables
    >(
        OVERVIEW_DOMAIN_OFFER_QUERY,
        {
            categoryId: categoryId,
            language: locale,
            domainId: domainId,
            slugs: slugs,
        },
        skipCache,
    )();
    return res.allDomainOffers
        ?.filter((item) => item?.offers?.length)
        .flatMap((item) => item?.offers) as GQLOffers[];
};

const getAllComparisonBuyersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const res = await fetchData<
        GQLCategoryOffersQueryQuery,
        GQLCategoryOffersQueryQueryVariables
    >(
        CATEGORY_OFFERS_QUERY,
        {
            categoryId: categoryId,
            language: locale,
            domainId: domainId,
        },
        skipCache,
    )();
    return res.allDomainOffers
        ?.filter((item) => item?.offers?.length)
        .flatMap((item) => item?.offers) as GQLOffers[];
};

const fetchExistModalQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
): Promise<GQLOffers[] | null> => {
    const res = await fetchData<
        GQLGetExitModalOffersQuery,
        GQLGetExitModalOffersQueryVariables
    >(
        EXIT_MODAL_OFFERS_QUERY,
        {
            categoryId: categoryId,
            domainId: domainId,
            language: locale,
        },
        skipCache,
    )();

    if (res.allDomainOffers?.length) {
        const offers = res?.allDomainOffers?.filter(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (item) => item?.placements?.displayOnExitWindow,
        );
        if (offers.length)
            return offers?.flatMap((item) => item?.offers) as GQLOffers[];
    }
    return null;
};

export const fetchThankOffersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
): Promise<GQLOffers[] | null> => {
    const res = await fetchDataFromFront<
        GQLGetExitModalOffersQuery,
        GQLGetExitModalOffersQueryVariables
    >(EXIT_MODAL_OFFERS_QUERY, {
        categoryId: categoryId,
        domainId: domainId,
        language: locale,
    })();

    if (res?.allDomainOffers?.length) {
        const offers = res?.allDomainOffers?.filter(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (item) => item?.placements?.showOnThankYou,
        );
        if (offers.length)
            return offers?.flatMap((item) => item?.offers) as GQLOffers[];
    }
    return null;
};

const fetchTop3BuyersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const res = await fetchData<
        GQLGetTop3BuyersQuery,
        GQLGetTop3BuyersQueryVariables
    >(
        TOP_3_BUYERS_QUERY,
        {
            language: locale,
            categoryId,
            domainId,
        },
        skipCache,
    )();

    if (res && res.allDomainOffers && res.allDomainOffers.length)
        return res?.allDomainOffers
            .filter((domainOffer) => domainOffer?.offers?.length)
            .flatMap((item) => item?.offers);
};

const bestOverallPageDomainOffersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLGetOverviewDomainOffersQuery,
        GQLGetOverviewDomainOffersQueryVariables
    >(
        BEST_OVERALL_DOMAIN_OFFER_QUERY,
        {
            categoryId: categoryId,
            domainId: domainId,
            language: locale,
        },
        skipCache,
    )();
    return data.allDomainOffers;
};
const ReviewPageDomainOffersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLGetReviewDomainOffersQuery,
        GQLGetReviewDomainOffersQueryVariables
    >(
        REVIEW_GET_DOMAIN_OFFER_QUERY,
        {
            categoryId: categoryId,
            domainId: domainId,
            language: locale,
        },
        skipCache,
    )();
    return data.allDomainOffers;
};
const ArticlesPageDomainOffersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLGetArticlesDomainOffersQuery,
        GQLGetArticlesDomainOffersQueryVariables
    >(
        ARTICLES_DOMAIN_OFFER_QUERY,
        {
            categoryId: categoryId,
            domainId: domainId,
            language: locale,
        },
        skipCache,
    )();
    return data.allDomainOffers;
};
const GetComparePageDomainOffersQuery = async (
    categoryId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLGetCompareDomainOffersQuery,
        GQLGetCompareDomainOffersQueryVariables
    >(
        COMPARE_DOMAIN_OFFER_QUERY,
        {
            categoryId: categoryId,
            domainId: domainId,
            language: locale,
        },
        skipCache,
    )();
    return data.allDomainOffers;
};

const GetVendorComparePageDomainOffer = async (
    offerId: number,
    locale: string,
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLVendorCompareGetDomainOffersQuery,
        GQLVendorCompareGetDomainOffersQueryVariables
    >(
        VENDOR_COMPARE_GET_DOMAIN_OFFER_QUERY,
        {
            offerId: offerId,
            domainId: domainId,
            locale: locale,
        },
        skipCache,
    )();
    return data.domainOffer;
};
const vendorCompareGetVendorsToCompare = async (
    categoryId: number,
    domainId: number,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLVendorCompareGetVendorsToCompareQuery,
        GQLVendorCompareGetVendorsToCompareQueryVariables
    >(
        VENDOR_COMPARE_GET_VENDORS_TO_COMPARE_QUERY,
        {
            categoryId: categoryId,
            domainId: domainId,
        },
        skipCache,
    )();
    return data.allDomainOffers;
};
const categoryComparePageQuery = async (
    categoryId: number,
    locale: string,
    skipCache: boolean = false,
) => {
    const data = await fetchData<
        GQLCompareGetCategoryByIdQuery,
        GQLCompareGetCategoryByIdQueryVariables
    >(
        COMPARE_GET_CATEGORY_BY_ID,
        {
            id: categoryId,
            language: locale,
        },
        skipCache,
    )();
    return data.category;
};

const categoriesWithFormsQuery = async (
    queryClient: QueryClient,
    domainId: number,
) => {
    await queryClient.fetchQuery<GQLAllDomainFormsQuery>({
        queryKey: ["categoriesWithForms"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllDomainFormsQuery,
                GQLAllDomainFormsQueryVariables
            >(CATEGORY_WITH_FORMS, { domainId: domainId })();
            return {
                allDomainForms: data.allDomainForms?.filter(
                    (item, index, self) =>
                        index ===
                        self.findIndex(
                            (t) => t?.categoryId === item?.categoryId,
                        ),
                ),
            };
        },
    });
};

// const articlesByCategoryIdPaginationQuery = async (
//     categoryId: number,
//     domainId: number,
// ) => {
//     const articles = await graphQLRequestServer.request(
//         CATEGORY_POSTS_BY_CATEGORY_ID,
//         {
//             limit: BLOGS_PER_PAGE_ARTICLES,
//             offset: 0,
//             id: categoryId,
//             domainId,
//             published: POSTS_IS_PUBLISHED_ARTICLES,
//             language: locale,
//         },
//         headers,
//     );
//     return articles;
// };

const articlesByCategoryIdPaginationQuery = async (
    categoryId: number,
    domainId: number,
    page: number,
    typeId: number,
) => {
    const data = await fetchData<
        GQLCategoryPostsByCategoryIdQuery,
        GQLCategoryPostsByCategoryIdQueryVariables
    >(CATEGORY_POSTS_BY_CATEGORY_ID, {
        limit: BLOGS_PER_PAGE_ARTICLES,
        offset: page * BLOGS_PER_PAGE_ARTICLES,
        id: categoryId,
        domainId,
        published: POSTS_IS_PUBLISHED_ARTICLES,
        language: locale,
        typeId,
    })();
    return data.category?.posts;
};

const fetchTotalArticles = async (
    categoryId: number,
    domainId: number,
    typeId: number,
) => {
    const data = await fetchData<
        GQLTotalArticlesQuery,
        GQLTotalArticlesQueryVariables
    >(TOTAL_ARTICLES, {
        id: categoryId,
        domainId,
        published: true,
        typeId,
    })();
    return data.category?.posts?.length;
};

const categorySlugPaginationQuery = async (
    queryClient: QueryClient,
    slug: string,
    id: number,
) => {
    await queryClient.prefetchInfiniteQuery<GQLCategoryBySlugPaginationQuery>({
        queryKey: [
            "postsByCategorySlug",
            {
                limit: BLOGS_PER_PAGE_GENERAL,
                offset: 0,
                domainId: id,
                slug: slug,
                language: locale,
            },
        ],
        queryFn: async () => {
            const data = await fetchData<
                GQLCategoryBySlugPaginationQuery,
                GQLCategoryBySlugPaginationQueryVariables
            >(CATEGORY_BY_SLUG_PAGINATION, {
                limit: BLOGS_PER_PAGE_GENERAL,
                offset: 0,
                domainId: id,
                slug: slug,
                language: locale,
            })();
            return data;
        },
    });

    queryClient.setQueryData(
        [
            "postsByCategorySlug",
            {
                offset: 0,
                limit: BLOGS_PER_PAGE_GENERAL,
                domainId: id,
                slug: slug,
                language: locale,
            },
        ],
        (data) => ({
            ...(data as InfiniteData<GQLCategoryBySlugPaginationQuery>),
            pageParams: [],
        }),
    );
};

const activeFormQuery = async (
    queryClient: QueryClient,
    category: GQLCategories,
    domainId: number,
    skipCache: boolean = false,
) => {
    if (category)
        return await queryClient.fetchQuery({
            queryKey: ["activeForm", category.slug],
            queryFn: async () => {
                const data = await fetchData<
                    GQLDomainForms,
                    GQLCategoryByIdQueryVariables
                >(
                    CATEGORY_BY_ID,
                    {
                        id: category.id as number,
                        domainId: domainId,
                        // formId: 268,
                    },
                    skipCache,
                )();
                return data;
            },
        });
};

const crossSellingData = async (
    queryClient: QueryClient,
    categoryId: number,
    domainId: number,
    skipCache: boolean = false,
) => {
    if (categoryId) {
        return await queryClient.fetchQuery({
            queryKey: ["crossSelling"],
            queryFn: async () => {
                const data = await fetchData<
                    GQLCrossSellingDomainFormQuery,
                    GQLCrossSellingDomainFormQueryVariables
                >(
                    CROSS_SELLING_DOMAIN_FORM,
                    {
                        id: domainId,
                        categoryId: categoryId,
                    },
                    skipCache,
                )();
                return data;
            },
        });
    }
};

const offerByIdQuery = async (
    queryClient: QueryClient,
    id: number,
    locale: string,
    skipCache: boolean = false,
    domainId: number,
) => {
    return await queryClient.fetchQuery<GQLOffers>({
        queryKey: ["offerById", id.toString()],
        queryFn: async () => {
            const data = await fetchData<
                GQLOfferByIdQuery,
                GQLOfferByIdQueryVariables
            >(
                OFFER_BY_ID,
                {
                    id: id,
                    language: locale,
                    domainId: domainId,
                },
                skipCache,
            )();
            return data?.offer as GQLOffers;
        },
    });
};

const postByIdQuery = async (
    queryClient: QueryClient,
    id: string,
    skipCache: boolean = false,
) => {
    return await queryClient.fetchQuery<GQLPosts>({
        queryKey: ["postById", id],
        queryFn: async () => {
            const data = await fetchData<GQLPostByIdQuery, PostVars>(
                POST_BY_ID,
                {
                    id: parseInt(id, 10),
                },
                skipCache,
            )();

            return data?.post as GQLPosts;
        },
    });
};
const postGetPostByIdQuery = async (
    id: string,
    domainId: number,
    skipCache: boolean = false,
): Promise<GQLPosts> => {
    const data = await fetchData<
        GQLGetPostByIdQuery,
        GQLGetPostByIdQueryVariables
    >(
        POST_GET_POST_BY_ID,
        {
            id: parseInt(id, 10),
            domainId: domainId,
        },
        skipCache,
    )();
    return data.post as GQLPosts;
};

const allPostsSiteMapQuery = async (id: number) => {
    const data = await fetchData<
        GQLAllPostsSiteMapQuery,
        GQLAllPostsSiteMapQueryVariables
    >(ALL_POSTS_SITE_MAP, {
        domainId: id,
        language: process.env.NEXT_PUBLIC_DEFAULT_LANG,
    })();
    return data.allPosts as GQLPosts[];
};
const featuredPostsHomePageQuery = async (
    queryClient: QueryClient,
    id: number,
) => {
    await queryClient.fetchQuery<GQLPosts[]>({
        queryKey: ["featuredPostsHomePage"],
        queryFn: async () => {
            const data = await fetchData<
                GQLFeaturedPostsHomePageQuery,
                GQLFeaturedPostsHomePageQueryVariables
            >(FEATURED_POSTS_HOME_PAGE, {
                domainId: id,
            })();
            return data?.allPosts as GQLPosts[];
        },
    });
};
const sitemapGetAllCategories = async (domainId: number) => {
    const data = await fetchData<
        GQLSitemapGetAllDomainCategoriesQuery,
        GQLSitemapGetAllDomainCategoriesQueryVariables
    >(SITEMAP_GET_ALL_DOMAIN_CATEGORIES, {
        domainId: domainId,
    })();
    return data.allDomainCategories as GQLDomainCategories[];
};

const sitemapGetAllOffers = async (domainId: number) => {
    const data = await fetchData<
        GQLSitemapGetAllDomainOffersQuery,
        GQLSitemapGetAllDomainOffersQueryVariables
    >(SITEMAP_GET_ALL_DOMAIN_OFFERS, {
        domainId: domainId,
    })();
    return data.allDomainOffers as GQLDomainOffers[];
};

const allCategoriesSlugs = async (queryClient: QueryClient, id: number) => {
    return await queryClient.fetchQuery<GQLCategories[]>({
        queryKey: ["allCategoriesSlugs"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllCategoriesSlugsQuery,
                GQLAllCategoriesCategorySlugQueryVariables
            >(ALL_CATEGORIES_SLUGS, {
                domainId: id,
            })();
            return data?.domain?.categories as GQLCategories[];
        },
    });
};
const allPostsByCategoriesSlugs = async (
    queryClient: QueryClient,
    query: string,
) => {
    return await queryClient.fetchQuery<CategoriesList>({
        queryKey: ["allPostsByCategoriesSlugs"],
        queryFn: async () => {
            const data = await fetchData<CategoriesList, undefined>(query)();
            return data;
        },
    });
};

const fetchTerndingNews = async (domainId: number) => {
    const data = await fetchData<
        GQLTrendingNewsQuery,
        GQLTrendingNewsQueryVariables
    >(TRENDING_NEWS, {
        domainId,
    })();
    return data.allPosts;
};

const fetchMissedNews = async (domainId: number) => {
    const data = await fetchData<
        GQLMissedNewsQuery,
        GQLMissedNewsQueryVariables
    >(MISSED_NEWS, {
        domainId,
    })();
    return data.allPosts;
};

const allCategoriesCategorySlug = async (
    queryClient: QueryClient,
    id: number,
) => {
    return await queryClient.fetchQuery<GQLCategories[]>({
        queryKey: ["allCategoriesCategorySlug"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllCategoriesCategorySlugQuery,
                GQLAllCategoriesCategorySlugQueryVariables
            >(ALL_CATEGORIES_CATEGORY_SLUG, {
                domainId: id,
            })();
            return data?.domain?.categories as GQLCategories[];
        },
    });
};
const tagSlugPaginationQuery = async (
    queryClient: QueryClient,
    slug: string,
    id: number,
    typeId: number,
) => {
    await queryClient.prefetchInfiniteQuery<GQLTagBySlugQuery>({
        queryKey: [
            "postsByTagSlug",
            {
                limit: BLOGS_PER_PAGE_GENERAL,
                offset: 0,
                domainId: id,
                slug: slug,
                typeId,
            },
        ],
        queryFn: async () => {
            const data = await fetchData<
                GQLTagBySlugQuery,
                GQLTagBySlugQueryVariables
            >(TAG_BY_SLUG, {
                limit: BLOGS_PER_PAGE_GENERAL,
                offset: 0,
                domainId: id,
                slug: slug,
                typeId,
            })();
            return data;
        },
    });

    queryClient.setQueryData(
        [
            "postsByTagSlug",
            {
                offset: 0,
                limit: BLOGS_PER_PAGE_GENERAL,
                domainId: id,
                slug: slug,
                typeId,
            },
        ],
        (data) => ({
            ...(data as InfiniteData<GQLTagBySlugQuery>),
            pageParams: [],
        }),
    );
};

const newsByTagPaginationQuery = async (
    slug: string,
    id: number,
    typeId: number,
    page: number,
) => {
    const data = await fetchData<GQLTagBySlugQuery, GQLTagBySlugQueryVariables>(
        TAG_BY_SLUG,
        {
            limit: 12,
            offset: page * 12,
            domainId: id,
            slug: slug,
            typeId,
        },
    )();
    return data.tag?.posts;
};

const fetchAllNewsPaginationQuery = async (
    id: number,
    typeId: number,
    page: number,
): Promise<GQLPosts[]> => {
    const data = await fetchData<GQLAllNewsQuery, GQLAllNewsQueryVariables>(
        ALL_NEWS,
        {
            limit: 12,
            offset: page * 12,
            domainId: id,
            typeId,
        },
    )();
    return data.allPosts as GQLPosts[];
};
const fetchAllPostsPaginationQuery = async (
    id: number,
    page: number,
    userId: string,
): Promise<GQLPosts[]> => {
    const data = await fetchData<
        GQLAllPostsWritersQuery,
        GQLAllPostsWritersQueryVariables
    >(ALL_POSTS_WRITERS, {
        limit: 12,
        offset: page * 12,
        domainId: id,
        userId: userId,
    })();
    return data.allPosts as GQLPosts[];
};

const fetchTotalNewsByTag = async (slug: string, id: number) => {
    const data = await fetchData<
        GQLTotalNewsByTagsQuery,
        GQLTotalNewsByTagsQueryVariables
    >(TOTAL_NEWS_BY_TAGS, {
        domainId: id,
        slug: slug,
    })();
    return data.tag?.posts?.length ?? 0;
};

const fetchTotalNews = async (id: number, typeId = 2296): Promise<number> => {
    const data = await fetchData<
        GQLTotalAllNewsQuery,
        GQLTotalAllNewsQueryVariables
    >(TOTAL_ALL_NEWS, {
        domainId: id,
        typeId,
    })();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (data.allPosts?.length as number) ?? 0;
};
const fetchTotalWritersPosts = async (
    id: number,
    userId: string,
): Promise<number> => {
    const data = await fetchData<
        GQLTotalAllWritersPostsQuery,
        GQLTotalAllWritersPostsQueryVariables
    >(TOTAL_ALL_WRITERS_POSTS, {
        domainId: id,
        userId: userId,
    })();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (data.allPosts?.length as number) ?? 0;
};
const fetchUserBySlug = async (userSlug: string): Promise<GQLUsers> => {
    const data = await fetchData<
        GQLUserBySlugQuery,
        GQLUserBySlugQueryVariables
    >(USER_BY_SLUG, {
        nicknameSlug: userSlug,
    })();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return data?.user as GQLUsers;
};

const allTagsQuery = async (queryClient: QueryClient) => {
    return await queryClient.fetchQuery<GQLTags[]>({
        queryKey: ["allTags"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllTagsQuery,
                GQLAllTagsQueryVariables
            >(ALL_TAGS)();
            return data?.allTags as GQLTags[];
        },
    });
};
const allDkisQuery = async (
    queryClient: QueryClient,
    skipCache: boolean = false,
) => {
    return await queryClient.fetchQuery<GQLDkis[]>({
        queryKey: ["allDkis"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllDkisQuery,
                GQLAllDkisQueryVariables
            >(ALL_DKIS, {}, skipCache)();

            return data?.allDkis as GQLDkis[];
        },
    });
};
const getAllDKIs = async (skipCache: boolean = false) => {
    const data = await fetchData<GQLAllDkisQuery, GQLAllDkisQueryVariables>(
        ALL_DKIS,
        {},
        skipCache,
    )();

    return data.allDkis;
};

type operation = {
    operation: string;
    value: string | string[];
};
type translationKeys = {
    translationKeys: operation[];
};
const getDomainId = async (
    queryClient: QueryClient,
    translationKeys: translationKeys,
): Promise<number> => {
    const domainData = await queryClient.fetchQuery<GQLDomainQuery>({
        queryKey: ["domain"],
        queryFn: async () => {
            const data = await fetchData<
                GQLDomainQuery,
                GQLDomainQueryVariables
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            >(DOMAIN_INFO(translationKeys), {
                language: locale,
            })();
            queryClient.setQueryData(["translations"], formatTranslation(data));
            // @ts-ignore
            const allTranslationKeys = Object.keys(data).filter((key) =>
                key.startsWith("Translation_"),
            );
            allTranslationKeys.forEach((translationKey) => {
                // @ts-ignore
                delete data[translationKey];
            });
            return data;
        },
    });

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return domainData.domain?.id as number;
};

const getTextBlocks = async (offers: any, queryClient: QueryClient) => {
    await queryClient.fetchQuery<GQLTextBlocksQuery>({
        queryKey: ["textBlocks"],
        queryFn: async () => {
            const data = await fetchData<
                GQLTextBlocksQuery,
                GQLTextBlocksQueryVariables
            >(TEXT_BLOCKS_QUERY, {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                offers,
            })();
            return data;
        },
    });
};

const getAllUrls = async (
    queryClient: QueryClient,
    pageType: string,
    id: string,
    skipCache: boolean = false,
) => {
    const allUrls = await queryClient.fetchQuery<GQLUrls[]>({
        queryKey: ["allUrls"],
        queryFn: async () => {
            const data = await fetchData<
                GQLAllUrlsQuery,
                GQLAllUrlsQueryVariables
            >(
                ALL_URL,
                {
                    model: pageType,
                    modelId: id,
                },
                skipCache,
            )();
            return data.allUrls as GQLUrls[];
        },
    });

    return allUrls;
};

const getComparisonMeta = async (queryClient: QueryClient, url: string) => {
    const allUrls = await queryClient.fetchQuery<GQLComparisonMetaQuery>({
        queryKey: ["comparisonMeta"],
        queryFn: async () => {
            const data = await fetchData<
                GQLComparisonMetaQuery,
                GQLComparisonMetaQueryVariables
            >(COMPARISON_META, {
                url: url,
            })();
            return data;
        },
    });

    return allUrls;
};

const getLookupsByIds = async (
    queryClient: QueryClient,
    ids: number[],
    language: string,
) => {
    const lookups = await queryClient.fetchQuery<GQLLookupsByIdsQuery>({
        queryKey: ["lookupsByIds"],
        queryFn: async () => {
            const data = await fetchData<
                GQLLookupsByIdsQuery,
                GQLLookupsByIdsQueryVariables
            >(LOOKUPS_BY_IDS, {
                ids,
                language,
            })();
            return data;
        },
    });

    return lookups;
};

export const initialCallPaths = () => {
    return {
        paths: [],
        fallback: "blocking",
    };
};

const getCategoryQuery = (categoryElem: GQLCategories, domainId: number) => {
    return `${
        categoryElem?.slug?.replace(/\-/g, "_") as string
    } : category(isPublished: true,  isDeleted: false, slug: "${
        categoryElem?.slug as string
    }") {
        id
        parentId
        title
        name
        slug
        label
        translations {
            key
            value
            language
        }
        posts(
            published: true
            orderByDesc: createdAt
            isDeleted: false
            limit: 6
            typeId: 2295
            domainId:${domainId}
        ) {
            id
            title
            image {
                fullPath
                path
            }
            url {
                url
            }
            translations {
                key
                value
                language
            }
        }
    }`;
};

const getTranslationKeys = (translationKeys: operation[]) => {
    const allTranslationKeys = {
        translationKeys: [
            {
                operation: "keyLike",
                value: "several_headErearnCommissionsFrom%",
            },
            {
                operation: "keyEq",
                value: "several_general_search_placeholder",
            },
            {
                operation: "keyEq",
                value: "several_homePage_footer_allRights",
            },
            {
                operation: "keyEq",
                value: "several_general_no_results_found",
            },
        ],
    };

    translationKeys.forEach((key) =>
        // @ts-ignore
        allTranslationKeys.translationKeys.push(key),
    );

    return allTranslationKeys;
};

const getSharedCategoryProps = async (
    queryClient: QueryClient,
    category: GQLCategories,
    domainId: number,
    domainOffers: GQLDomainOffers[],
    activeTab: string,
) => {
    const promise2 = await Promise.all([
        overviewGetTextBlocksByPositions(category.id as number, domainId, [
            "several-top-buyers",
            "several-offers-list-title",
        ]),
        getAllUrls(
            queryClient,
            "categories",
            category.id?.toString() as string,
        ),
        activeFormQuery(queryClient, category, domainId),
        allDkisQuery(queryClient),
    ]);

    const textBlocks = promise2[0];
    const allUrlsResponse = promise2[1];
    const activeForm = promise2[2];
    const dkis = promise2[3];

    const topBuyersBriefTextBlock =
        textBlocks && textBlocks.length
            ? (textBlocks.find(
                  (item) => item.position === "several-top-buyers",
              ) as GQLTextBlocks | undefined)
            : "";

    const offersListTextBlock =
        textBlocks && textBlocks.length
            ? (textBlocks.find(
                  (item) => item.position === "several-offers-list-title",
              ) as GQLTextBlocks | undefined)
            : "";

    const existModalOffers = domainOffers
        .filter(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            (item) => item?.placements?.displayOnExitWindow,
        )
        .flatMap((item) =>
            item.offers?.map((offer) => ({
                logo: offer?.logo,
                title: offer?.title,
                features: offer?.features,
                slug: offer?.slug,
                url: offer?.url,
            })),
        ) as GQLOffers[];

    const top3Buyers = domainOffers.flatMap((item) =>
        item.offers?.slice(0, 3),
    ) as GQLOffers[];

    const sharedProps: ICategoryPageSharedProps = {
        dkis: dkis,
        textBlock: (topBuyersBriefTextBlock as GQLTextBlocks) ?? {},
        categoryLabel: category.label ?? (category.name as string),
        categoryName: category.name as string,
        topBuyers: top3Buyers && top3Buyers.length ? top3Buyers : [],
        activeTab: activeTab,
        existModalOffers:
            existModalOffers && existModalOffers.length ? existModalOffers : [],
        url: category.url as GQLUrls,
    };

    queryClient.setQueryData(["categoryPageSharedProps"], sharedProps);

    return {
        offersListTextBlock,
        allUrlsResponse,
        activeForm,
    };
};

const generateAvailableUrls = (
    queryClient: QueryClient,
    languages: string[] = [],
    mainUrl?: GQLUrls | string | null,
    fallbackUrl?: string,
    categoryFallbackLanguages?: string[],
) => {
    if (categoryFallbackLanguages && categoryFallbackLanguages.length) {
        const availableUrls = LANGUAGES.map((language) => ({
            isMain: true,
            url:
                languages.includes(language) && mainUrl
                    ? typeof mainUrl === "string"
                        ? mainUrl
                        : resolveURL(mainUrl)
                    : categoryFallbackLanguages?.find(
                          (item) => item === language,
                      )
                    ? fallbackUrl
                    : "/",
            language: language,
        })) as GQLUrls[];
        queryClient.setQueryData(["availableUrls"], availableUrls);
    } else {
        const availableUrls = LANGUAGES.map((language) => ({
            isMain: true,
            url:
                languages.includes(language) && mainUrl
                    ? typeof mainUrl === "string"
                        ? mainUrl
                        : resolveURL(mainUrl)
                    : fallbackUrl
                    ? fallbackUrl
                    : "/",
            language: language,
        })) as GQLUrls[];
        queryClient.setQueryData(["availableUrls"], availableUrls);
    }
};
const generateAvailablePostUrls = (
    queryClient: QueryClient,
    urls: GQLUrls[],
    fallbackUrl?: string,
) => {
    const availableUrls: GQLUrls[] = LANGUAGES.map((language) =>
        urls.find((item) => item.language === language)
            ? { ...urls.find((item) => item.language === language) }
            : {
                  ...urls[0],
                  language: language,
                  url: fallbackUrl,
                  main: { url: fallbackUrl },
              },
    );
    queryClient.setQueryData(["availableUrls"], availableUrls);
};

const initialCall = async (
    page: string,
    ctx: GetStaticPropsContext | GetServerSidePropsContext,
) => {
    try {
        const pages = [
            "HomePage",
            "Categories",
            "OverView",
            "BestOverAllByCategoryId",
            "Articles",
            "Compare",
            "CompareSlug",
            "Comparison",
            "Vendor",
            "Blog",
            "BlogId",
            "Preview",
            "CategorySlug",
            "CategorySlugIndex",
            "AboutUs",
            "WhatWeDo",
            "PartnerWithUs",
            "ContactUs",
            "PrivacyPolicy",
            "SiteMap",
            "TagSlug",
            "404",
            "Search",
            "CategoryId",
            "VendorId",
            "VendorCompareById",
            "PreviewOffer",
            "CategoryCompare",
            "ArticlesById",
            "CategoryByIdCompareSlug",
            "News",
            "NewsId",
            "NewsById",
            "NewsTagSlug",
            "allNewsTagsPage",
            "WritersPage",
        ];
        if (!pages.includes(page)) {
            return {
                notFound: true,
            };
        }

        switch (page) {
            case "404": {
                const queryClient404Page = new QueryClient(DEFAULT_OPTIONS);
                await getDomainId(
                    queryClient404Page,
                    getTranslationKeys([
                        {
                            operation: "keyLike",
                            value: "several_notFoundPage%",
                        },
                    ]),
                );
                return {
                    props: {
                        pageType: "404",
                        dehydratedState: dehydrate(queryClient404Page),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "HomePage": {
                const queryClientHomePage = new QueryClient(DEFAULT_OPTIONS);

                const domainId = await getDomainId(
                    queryClientHomePage,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_category_select_placeholder",
                                "several_general_search",
                                "several_general_vendor_select_placeholder",
                                "several_general_compareButton",

                                "several_general_getQuoteButton",
                                "several_general_showMoreButton",
                                "several_general_readMoreButton",
                                "several_general_showLessButton",
                                "several_general_visitWebsite",
                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_searchPage-meta-title-2",
                                "several_general_meta_description",
                            ],
                        },
                        {
                            operation: "keyLike",
                            value: "several_homePage_%",
                        },
                    ]),
                );
                await Promise.all([
                    featuredPostsHomePageQuery(queryClientHomePage, domainId),
                    categoriesAndOffersDropDownQuery(
                        queryClientHomePage,
                        domainId,
                    ),
                    homePageTopVendorsQuery(queryClientHomePage, domainId),
                    categoriesWithFormsQuery(queryClientHomePage, domainId),
                ]);
                generateAvailableUrls(
                    queryClientHomePage,
                    LANGUAGES,
                    null,
                    "/",
                );

                queryClientHomePage.setQueryData(["activePage"], "homePage");
                return {
                    props: {
                        pageType: "home",
                        dehydratedState: dehydrate(queryClientHomePage),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "Categories": {
                const queryClientCategories = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientCategories,
                    getTranslationKeys([
                        {
                            operation: "keyEq",
                            value: "several_general_showMoreButton",
                        },
                        {
                            operation: "keyIn",
                            value: [
                                "several_categoriesPage_meta_title",
                                "several_categoriesPage_meta_description",
                            ],
                        },
                        {
                            operation: "keyLike",
                            value: "several_categoriesPage_%",
                        },
                    ]),
                );
                const promises = await Promise.all([
                    categoriesGetAllCategories(domainId),
                    categoriesPageDomainOffers(domainId),
                ]);
                const categories = promises[0];
                const domainOffers = promises[1];

                const pageProps: ICategoriesPageProp = {
                    categories: categories,
                    domainOffers: domainOffers,
                };
                generateAvailableUrls(
                    queryClientCategories,
                    LANGUAGES,
                    null,
                    "/categories",
                );

                queryClientCategories.setQueryData(
                    ["activePage"],
                    "categories",
                );
                queryClientCategories.setQueryData(
                    ["categoriesPageProps"],
                    pageProps,
                );

                return {
                    props: {
                        pageType: "categories",
                        dehydratedState: dehydrate(queryClientCategories),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "CategoryId": {
                const queryClientOverView = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const promise = await Promise.all([
                    getDomainId(
                        queryClientOverView,
                        getTranslationKeys([
                            {
                                operation: "keyIn",
                                value: [
                                    "several_general_share",
                                    "several_general_recentPosts",
                                    "several_general_contents",
                                    "several_general_Read review",
                                    "several_general_sideWidget_title",
                                    "several_general_visitWebsite",
                                    "several_general_prosWord",
                                    "several_general_consWord",
                                    "several_general_readWord",
                                    "several_general_reviewWord",
                                    "several_general_formLabelTopOfTheForm",
                                    "several_general_breadcrumb_home",
                                    "several_category_sideWidget_about",
                                    "several_category_sideWidget_description",
                                    "several_general_freeQuotePopupTitle",
                                    "several_general_freeQuotePopupDescription",
                                    "several_general_freeQuotePopupButton",
                                    "several_general_formModalText",
                                    "several_general_formModalBackPreviousPage",
                                    "several_general_seeMore",
                                    "several_category_sideWidget_bestInCategory",
                                    "several_categoryPage_checkBestCompanies",
                                    "several_category_topBuyerBrief",
                                    "several_general-get-a-quote",
                                    "several_general_meta_description",
                                    "several_general_newsWord",
                                ],
                            },
                            {
                                operation: "keyLike",
                                value: "several_category_mainTab_%",
                            },
                        ]),
                    ),
                    overviewGetCategoryByIdQuery(
                        parseInt((params?.id as string) ?? "0"),
                        locale as string,
                    ),
                ]);

                const domainId = promise[0];
                const category = promise[1] as GQLCategories;
                //@ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const categoryLanguages = category?.languages as string[];

                if (!category || !category.id) {
                    return {
                        notFound: true,
                    };
                }
                const domainOffers = await getOffersByCategoryId(
                    category.id,
                    domainId,
                    locale as string,
                );
                const recentPosts = await getRecentPostsByCategoryQuery(
                    domainId,
                    category.id,
                );

                const { offersListTextBlock, allUrlsResponse, activeForm } =
                    await getSharedCategoryProps(
                        queryClientOverView,
                        category,
                        domainId,
                        domainOffers,
                        "overview",
                    );

                if (domainOffers && domainOffers.length === 0) {
                    return {
                        notFound: true,
                    };
                }

                const categoryFaqs = category?.faqs?.map((item) => ({
                    id: item?.id,
                    title: item?.question,
                    content: item?.answer,
                }));
                const categoryTopics = category?.topics?.map((item) => ({
                    title: item?.title as string,
                    link: resolveURL(item?.url as GQLUrls),
                }));

                if (category?.slug === "auto-warranty") {
                    await crossSellingData(
                        queryClientOverView,
                        category?.id,
                        domainId,
                    );
                }

                queryClientOverView.setQueryData(
                    ["activeFormCategorySlug"],
                    category.slug,
                );
                queryClientOverView.setQueryData(
                    ["activeFormCategoryId"],
                    category?.id,
                );
                queryClientOverView.setQueryData(
                    ["tcpaMessage"],
                    category?.TCPAMessage,
                );

                if (!activeForm) {
                    queryClientOverView.setQueryData(
                        ["activeFormCategorySlug"],
                        null,
                    );
                    queryClientOverView.setQueryData(
                        ["activeFormCategoryId"],
                        null,
                    );
                    queryClientOverView.setQueryData(["tcpaMessage"], null);
                    queryClientOverView.setQueryData(
                        ["activeForm", category.slug],
                        null,
                    );
                }
                generateAvailableUrls(
                    queryClientOverView,
                    categoryLanguages,
                    category.url as GQLUrls,
                    "/categories",
                );

                const pageProps: ICategoryByIdPageProps = {
                    category: category,
                    domainOffers: domainOffers,
                    offers: domainOffers
                        .flatMap((item) => item.offers)
                        .map((item) => ({
                            ...item,
                            attributes: item?.attributes?.filter(
                                (attribute) =>
                                    attribute?.baseAttribute !== null,
                            ),
                        })) as GQLOffers[],
                    offersListTextBlock: offersListTextBlock ?? null,
                    topics: categoryTopics,
                    categoryFAQs: categoryFaqs,
                    recentPosts: recentPosts,
                };

                queryClientOverView.setQueryData(
                    ["categoryPageProps"],
                    pageProps,
                );

                return {
                    props: {
                        pageType: "category",
                        title: allUrlsResponse?.filter(
                            (item) =>
                                item?.language ===
                                process.env.NEXT_PUBLIC_DEFAULT_LANG,
                        )?.[0]?.title,
                        categoryId: category.id,
                        dehydratedState: dehydrate(queryClientOverView),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "BestOverAllByCategoryId": {
                const queryClientBestOverAll = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const id = params?.id;
                const domainId = await getDomainId(
                    queryClientBestOverAll,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_categoryPage_topWord",
                                "several_general_readInOurBlog",
                                "several_general_formLabelTopOfTheForm",
                                "several_general_Read review",
                                "several_general_visitWebsite",
                                "several_general_compareButton",
                                "several_general_breadcrumb_home",

                                "several_general_freeQuotePopupTitle",
                                "several_general_freeQuotePopupDescription",
                                "several_general_freeQuotePopupButton",

                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_categoryPage_companiesInWord",
                                "several_bestOverAll_comparingWord",
                                "several_category_topBuyerBrief",
                                "several_general-DiscountWord",
                                "several_general_meta_description",
                                "several_category_mainTab_bestOverAll",
                                "several_siteMapPage_Vendors",
                                "several_general_newsWord",
                            ],
                        },
                        {
                            operation: "keyLike",
                            value: "several_category_mainTab_%",
                        },
                    ]),
                );

                const promises = await Promise.all([
                    bestOverallGetCategoryByIdQuery(
                        parseInt(id as string, 10),
                        locale as string,
                    ),
                    bestOverallPageDomainOffersQuery(
                        parseInt(id as string, 10),
                        locale as string,
                        domainId,
                    ),
                ]);

                const category = promises[0];
                const domainOffers = promises[1];
                //@ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const categoryLanguages = category?.languages as string[];
                if (!category || !category.id) {
                    return {
                        notFound: true,
                    };
                }

                const { allUrlsResponse, activeForm } =
                    await getSharedCategoryProps(
                        queryClientBestOverAll,
                        category as GQLCategories,
                        domainId,
                        domainOffers as GQLDomainOffers[],
                        "comparison",
                    );

                if (category) {
                    if (category?.slug === "auto-warranty") {
                        await crossSellingData(
                            queryClientBestOverAll,
                            category?.id,
                            domainId,
                        );
                    }

                    queryClientBestOverAll.setQueryData(
                        ["activeFormCategorySlug"],
                        params?.category,
                    );
                    queryClientBestOverAll.setQueryData(
                        ["activeFormCategoryId"],
                        category?.id,
                    );
                    queryClientBestOverAll.setQueryData(
                        ["tcpaMessage"],
                        category?.TCPAMessage,
                    );

                    if (!activeForm) {
                        queryClientBestOverAll.setQueryData(
                            ["activeFormCategorySlug"],
                            null,
                        );
                        queryClientBestOverAll.setQueryData(
                            ["activeFormCategoryId"],
                            null,
                        );
                        queryClientBestOverAll.setQueryData(
                            ["tcpaMessage"],
                            null,
                        );
                        queryClientBestOverAll.setQueryData(
                            ["activeForm", category.slug],
                            null,
                        );
                    }
                }

                generateAvailableUrls(
                    queryClientBestOverAll,
                    categoryLanguages,
                    category.url as GQLUrls,
                    "/categories",
                );

                const pageProps: IBestOverallPageProps = {
                    category: category,
                    offers: domainOffers as GQLOffers[],
                };
                queryClientBestOverAll.setQueryData(
                    ["bestOverallPageProps"],
                    pageProps,
                );
                return {
                    props: {
                        pageType: "category",
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                process.env.NEXT_PUBLIC_DEFAULT_LANG,
                        )[0].title,
                        dehydratedState: dehydrate(queryClientBestOverAll),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "ArticlesById":
            case "NewsById": {
                const isNews = page === "NewsById";
                const queryClientArticles = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const id = params?.id;
                const currentPage = parseInt(params?.page?.[0] ?? "1");
                const domainId = await getDomainId(
                    queryClientArticles,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_categoryPage_topWord",
                                "several_general_readInOurBlog",
                                "several_general_formLabelTopOfTheForm",
                                "several_general_Read review",
                                "several_general_visitWebsite",
                                "several_general_compareButton",
                                "several_general_breadcrumb_home",
                                "several_general_discoverTopBlogs",
                                "several_articlesPage_mainDescription",
                                "several_general_readMoreButton",
                                "several_general_loading",

                                "several_general_freeQuotePopupTitle",
                                "several_general_freeQuotePopupDescription",
                                "several_general_freeQuotePopupButton",

                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_category_topBuyerBrief",
                                "several_articlesPage_noArticlesMessage",
                                "several_general_seeOurBlogs",
                                "several_general_meta_description",
                                "several_category_mainTab_articles",
                                "several_general_newsWord",
                                "several_general_discoverTopNews",
                                "several_newsPage_mainDescription",
                            ],
                        },
                        {
                            operation: "keyLike",
                            value: "several_category_mainTab_%",
                        },
                    ]),
                );
                const category = await articlesGetCategoryByIdQuery(
                    parseInt(id as string, 10),
                    locale as string,
                );
                if (!category || !category.id) {
                    return {
                        notFound: true,
                    };
                }
                if (currentPage === 0) {
                    return {
                        redirect: {
                            destination: `${resolveURL(
                                category.url as GQLUrls,
                            )}/${isNews ? "news-articles" : "articles"}`,
                            permanent: true,
                        },
                    };
                }
                const domainOffers = await ArticlesPageDomainOffersQuery(
                    category.id,
                    locale as string,
                    domainId,
                );
                const { allUrlsResponse } = await getSharedCategoryProps(
                    queryClientArticles,
                    category,
                    domainId,
                    domainOffers as GQLDomainOffers[],
                    isNews ? "news-articles" : "articles",
                );
                const articles = await articlesByCategoryIdPaginationQuery(
                    parseInt(id as string, 10),
                    domainId,
                    currentPage - 1,
                    isNews ? 2296 : 2295,
                );
                const total = await fetchTotalArticles(
                    parseInt(id as string, 10),
                    domainId,
                    isNews ? 2296 : 2295,
                );

                const categoryLanguages = category?.languages as string[];
                generateAvailableUrls(
                    queryClientArticles,
                    categoryLanguages,
                    category.url as GQLUrls,
                    `/${isNews ? "news" : "categories"}`,
                );
                const pageProps: ICategoryArticlesPageProps = {
                    category: category,
                    currentPage: currentPage,
                    total: total as number,
                    articles: articles as GQLPosts[],
                    isNews,
                };
                queryClientArticles.setQueryData(["activePage"], "categories");
                queryClientArticles.setQueryData(
                    ["articlesPageProps"],
                    pageProps,
                );
                return {
                    props: {
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                process.env.NEXT_PUBLIC_DEFAULT_LANG,
                        )[0].title,
                        pageType: "category",
                        dehydratedState: dehydrate(queryClientArticles),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "CategoryCompare": {
                const queryClientCompare = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx as GetStaticPropsContext;
                const categoryId = params?.id;
                const domainId = await getDomainId(
                    queryClientCompare,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_visitWebsite",
                                "several_general_Read review",
                                "several_category_compare_page_title",
                                "several_comparePage_pressAddCompare",
                                "several_comparePage_addCompare",
                                "several_comparePage_chooseProductsCompare",
                                "several_comparePage_chooseVendorsComparisons",
                                "several_comparePage_selectAll",
                                "several_comparePage_ItemsSelected",
                                "several_general_compareButton",
                                "several_general_freeQuotePopupTitle",
                                "several_general_freeQuotePopupDescription",
                                "several_general_freeQuotePopupButton",

                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_category_topBuyerBrief",
                                "several_general_meta_description",
                                "several_siteMapPage_Vendors",
                                "several_general_newsWord",
                            ],
                        },
                        {
                            operation: "keyLike",
                            value: "several_category_mainTab_%",
                        },
                    ]),
                );
                const promises = await Promise.all([
                    categoryComparePageQuery(
                        parseInt((categoryId as string) ?? "0"),
                        locale as string,
                    ),
                    GetComparePageDomainOffersQuery(
                        parseInt((categoryId as string) ?? "0"),
                        locale as string,
                        domainId,
                    ),
                ]);
                const category = {
                    ...promises[0],
                    attributes: promises[0]?.attributes?.filter(
                        (attr) => attr?.attributesGroup,
                    ),
                };
                const domainOffers = promises[1];

                if (!category || !category.id) {
                    return {
                        notFound: true,
                    };
                }

                const { allUrlsResponse } = await getSharedCategoryProps(
                    queryClientCompare,
                    category as GQLCategories,
                    domainId,
                    domainOffers as GQLDomainOffers[],
                    "compare",
                );
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const categoryLanguages = category?.languages as string[];
                generateAvailableUrls(
                    queryClientCompare,
                    categoryLanguages,
                    category.url as GQLUrls,
                    "/categories",
                );

                const pageProps: ICompareCategoryProps = {
                    category,
                    allCategoryOffers: domainOffers?.flatMap(
                        (item) => item?.offers,
                    ) as GQLOffers[],
                };

                queryClientCompare.setQueryData(["activePage"], "categories");
                queryClientCompare.setQueryData(
                    ["compareCategoryProps"],
                    pageProps,
                );
                return {
                    props: {
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                process.env.NEXT_PUBLIC_DEFAULT_LANG,
                        )[0].title,
                        pageType: "category",
                        dehydratedState: dehydrate(queryClientCompare),
                    },
                };
            }
            case "CategoryByIdCompareSlug": {
                const queryClientCompareSlug = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx as GetStaticPropsContext;
                const categoryId = params?.id;
                const offerSlugs = (params?.slug as string)?.split("-vs-");
                const domainId = await getDomainId(
                    queryClientCompareSlug,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_visitWebsite",
                                "several_general_Read review",
                                "several_comparePage_pressAddCompare",
                                "several_comparePage_addCompare",
                                "several_comparePage_chooseProductsCompare",
                                "several_comparePage_chooseVendorsComparisons",
                                "several_comparePage_selectAll",
                                "several_comparePage_ItemsSelected",
                                "several_general_compareButton",
                                "several_general_prosWord",
                                "several_general_consWord",
                                "several_comparisons-quickOverviewTitle",
                                "several_comparisons-quickStatsTitle",
                                "several_general_sellingPointsWord",
                                "several_general_formLabelTopOfTheForm",

                                "several_general_freeQuotePopupTitle",
                                "several_general_freeQuotePopupDescription",
                                "several_general_freeQuotePopupButton",

                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_category_compare_page_tooltip",
                                "several_category_topBuyerBrief",
                                "several_general_meta_description",
                                "several_general_newsWord",
                            ],
                        },
                        {
                            operation: "keyLike",
                            value: "several_category_mainTab_%",
                        },
                    ]),
                );
                const trailingWords = ["vpn", "antivirus", "pos", "crm"];

                const extraSlugs = trailingWords.flatMap((word) => {
                    return offerSlugs.map((slug) => {
                        if (slug.includes(word)) return slug;
                        return `${slug}-${word}`;
                    });
                });

                const promises = await Promise.all([
                    allDkisQuery(queryClientCompareSlug),
                    categoryByIdQuery(
                        queryClientCompareSlug,
                        parseInt(categoryId as string),
                        locale as string,
                    ),
                    getAllUrls(
                        queryClientCompareSlug,
                        "categories",
                        (categoryId as string) ?? "0",
                    ),
                    getTextBlocksByPositions(
                        queryClientCompareSlug,
                        parseInt((params?.id as string) ?? "0"),
                        domainId,
                        ["several-top-buyers", "several-offers-list-title"],
                    ),
                    overviewPageDomainOffersQuery(
                        parseInt(categoryId as string),
                        locale as string,
                        domainId,
                        [...offerSlugs, ...extraSlugs],
                    ),
                    getAllComparisonBuyersQuery(
                        parseInt(categoryId as string),
                        locale as string,
                        domainId,
                    ),
                    fetchExistModalQuery(
                        parseInt(categoryId as string),
                        locale as string,
                        domainId,
                    ),
                    fetchTop3BuyersQuery(
                        parseInt(categoryId as string),
                        locale as string,
                        domainId,
                    ),
                ]);
                let shouldRedirect = false;
                const dkis = promises[0];
                const category = promises[1];
                const textBlocks = promises[3];
                const domainOffers = promises[4];
                const allCategoryOffers = promises[5];
                const exitModalOffers = promises[6];
                const top3Buyers = promises[7];
                if (!category?.id) {
                    return {
                        notFound: true,
                    };
                }

                const preDefinedListTypeAttributes = domainOffers
                    .map((offer) => offer.attributes)
                    .flat()
                    .filter((attribute) => {
                        return (
                            attribute?.baseAttribute?.type === "PredefinedList"
                        );
                    })
                    .map((attribute) => {
                        let id: number = 0;
                        try {
                            const parsed = JSON.parse(
                                attribute?.value as string,
                            ) as {
                                [x: string]: string;
                            };

                            id = parseInt(parsed.id);
                        } catch (error) {
                            id = parseInt(attribute?.value as string, 10);
                        }
                        return id;
                    });

                let lookups: GQLLookupsByIdsQuery | undefined = undefined;

                if (preDefinedListTypeAttributes?.length) {
                    lookups = await getLookupsByIds(
                        queryClientCompareSlug,
                        preDefinedListTypeAttributes ?? [],
                        locale as string,
                    );
                }

                if (lookups) {
                    domainOffers.forEach((offer) => {
                        offer.attributes = getReplacedPredefinedListAttributes(
                            offer.attributes as GQLCategoryAttributesValues[],
                            lookups as GQLLookupsByIdsQuery,
                        );
                    });
                }

                // GET TEXT BLOCKS FOR CATEGORY
                const topBuyersBriefTextBlock = textBlocks.find(
                    (item) => item.position === "several-top-buyers",
                );
                queryClientCompareSlug.setQueryData(
                    ["topBuyersTextBlock"],
                    topBuyersBriefTextBlock,
                );

                queryClientCompareSlug.setQueryData(
                    ["overviewPageDomainOffers", category.id?.toString()],
                    domainOffers,
                );

                const sharedProps: ICategoryPageSharedProps = {
                    dkis: dkis,
                    textBlock: (topBuyersBriefTextBlock as GQLTextBlocks) ?? {},
                    categoryLabel: category.label ?? (category.name as string),
                    categoryName: category.name as string,
                    topBuyers: top3Buyers as GQLOffers[],
                    activeTab: "compare",
                    existModalOffers: exitModalOffers as GQLOffers[],
                    url: category.url as GQLUrls,
                };

                queryClientCompareSlug.setQueryData(
                    ["categoryPageSharedProps"],
                    sharedProps,
                );
                queryClientCompareSlug.setQueryData(["compareCategoryProps"], {
                    category: category,
                    domainOffers: domainOffers,
                    allCategoryOffers: allCategoryOffers,
                });

                const offersSlugsUrls: {
                    offer: {
                        slug: string;
                        slugAlias: string | null;
                        originalSlug?: string | undefined;
                        id: number | undefined;
                    };
                    match: string;
                }[] = [];
                const offerIsLike = (offerSlug: string): boolean => {
                    let found = false;
                    // loop over every slug
                    offerSlugs.forEach((item) => {
                        // if the current slug equals or contains one of the words that needs to be remove
                        // the case for POS is different since POS has multiple variants
                        // examples of slugs that have prohibited word: astrill-vpn, toast-pos
                        // anything after -[prohibited_word] need to be removed
                        if (
                            `${item}-${
                                category.slug === "point-of-sale" ||
                                category.slug === "point-of-sale-systems"
                                    ? "pos"
                                    : (category.slug as string)
                            }` === offerSlug ||
                            offerSlug.includes(item)
                        ) {
                            found = true;
                        }
                    });
                    return found;
                };

                // check if category exists and fetch form
                if (category?.id) {
                    const activeForm: GQLDomainForms | undefined =
                        await activeFormQuery(
                            queryClientCompareSlug,
                            category,
                            domainId,
                        );

                    queryClientCompareSlug.setQueryData(
                        ["activeFormCategorySlug"],
                        category.slug === "pos" ||
                            category.slug === "point-of-sale-systems"
                            ? "point-of-sale"
                            : (category.slug as string),
                    );
                    queryClientCompareSlug.setQueryData(
                        ["activeFormCategoryId"],
                        category?.id,
                    );
                    queryClientCompareSlug.setQueryData(
                        ["tcpaMessage"],
                        category?.TCPAMessage,
                    );

                    if (!activeForm) {
                        queryClientCompareSlug.setQueryData(
                            ["activeFormCategorySlug"],
                            null,
                        );
                        queryClientCompareSlug.setQueryData(
                            ["activeFormCategoryId"],
                            null,
                        );
                        queryClientCompareSlug.setQueryData(
                            ["tcpaMessage"],
                            null,
                        );
                        queryClientCompareSlug.setQueryData(
                            [
                                "activeForm",
                                category.slug === "pos" ||
                                category.slug === "point-of-sale-systems"
                                    ? "point-of-sale"
                                    : (category.slug as string),
                            ],
                            null,
                        );
                    }

                    queryClientCompareSlug.setQueryData(
                        ["activeTab"],
                        "compare",
                    );
                    queryClientCompareSlug.setQueryData(
                        ["activePage"],
                        "categories",
                    );

                    if (category.slug === "auto-warranty") {
                        await crossSellingData(
                            queryClientCompareSlug,
                            category.id,
                            domainId,
                        );
                    }
                } else {
                    return {
                        notFound: true,
                    };
                }
                const selectedOffersBySlug: Maybe<GQLOffers>[] | undefined =
                    category && category?.id
                        ? domainOffers?.filter((offer) => {
                              if (
                                  offerSlugs.includes(offer?.slug as string) ||
                                  offerIsLike(offer?.slug as string)
                              ) {
                                  offersSlugsUrls.push({
                                      offer: {
                                          slug: offer?.slug as string,
                                          slugAlias: offer?.slugAlias as string,
                                          id: offer?.id as number,
                                      },
                                      match: "slug",
                                  });
                                  return offer;
                              }

                              if (
                                  offerSlugs.includes(
                                      offer?.slugAlias as string,
                                  )
                              ) {
                                  shouldRedirect = true;
                                  offersSlugsUrls.push({
                                      offer: {
                                          slug: offer?.slug as string,
                                          slugAlias: offer?.slugAlias as string,
                                          originalSlug: offer?.slug as string,
                                          id: offer?.id as number,
                                      },
                                      match: "slugAlias",
                                  });
                                  offer["slug"] = offer?.slugAlias;
                                  return offer;
                              }
                          })
                        : [];

                // if selectedOffersBySlug.length > offerSlugs.length
                // thats mean there are more than one offer matched with there slug with the offer slug passed through url
                // then we should try to exact match with the offer slug passed through url
                let exactMatch: Maybe<GQLOffers>[] | undefined = [];
                if (selectedOffersBySlug?.length > offerSlugs.length) {
                    exactMatch = selectedOffersBySlug?.filter((offer) =>
                        offerSlugs.some((value) => offer?.slug === value),
                    );
                }

                if (selectedOffersBySlug?.length < offerSlugs.length) {
                    return {
                        notFound: true,
                    };
                }
                const sortArrayByOrder = (
                    arrayToSort: Maybe<GQLOffers>[] | undefined,
                ) => {
                    return arrayToSort?.sort(
                        (a: Maybe<GQLOffers>, b: Maybe<GQLOffers>) => {
                            const removedWordA = removeWordFromString(
                                a?.slug as string,
                            );
                            const removedWordB = removeWordFromString(
                                b?.slug as string,
                            );
                            if (
                                !offerSlugs.find(
                                    (item) => item === removedWordA,
                                )
                            ) {
                                shouldRedirect = true;
                            }
                            if (
                                !offerSlugs.find(
                                    (item) => item === removedWordB,
                                )
                            ) {
                                shouldRedirect = true;
                            }
                            if (a?.slug && b?.slug)
                                return a?.slug.localeCompare(b.slug);
                            return 0;
                        },
                    );
                };

                queryClientCompareSlug.setQueryData(
                    ["offersToCompare", { offerSlugs }],
                    exactMatch?.length
                        ? sortArrayByOrder(exactMatch)
                        : sortArrayByOrder(selectedOffersBySlug),
                );
                const orderedSlugs = exactMatch?.length
                    ? sortArrayByOrder(exactMatch)
                    : sortArrayByOrder(selectedOffersBySlug);

                const categoryMainUrl = `${resolveURL(
                    category.url as GQLUrls,
                )}/comparison/${(
                    orderedSlugs?.map((item) =>
                        removeWordFromString(item?.slug as string),
                    ) as string[]
                ).join("-vs-")}`;

                const offersIds = offersSlugsUrls
                    ?.map((item) => item.offer.id)
                    ?.sort((a, b) => (a ?? 0) - (b ?? 0));

                await getTextBlocks(offersIds, queryClientCompareSlug);
                const categoryLanguages = category?.languages as string[];
                const offersLanguages = selectedOffersBySlug.map(
                    (item) => item?.languages as string,
                );
                const languagesArray = findIntersection(
                    categoryLanguages,
                    // @ts-ignore
                    ...offersLanguages.map((item) => item),
                );
                generateAvailableUrls(
                    queryClientCompareSlug,
                    languagesArray as string[],
                    categoryMainUrl,
                    "/categories",
                );

                if (shouldRedirect) {
                    return {
                        redirect: {
                            destination: `${categoryMainUrl}`,
                            permanent: true,
                        },
                    };
                }

                await getComparisonMeta(
                    queryClientCompareSlug,
                    categoryMainUrl,
                );
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientCompareSlug),
                        offerSlugs: offerSlugs,
                        pageType: "category",
                        categoryMainUrl,
                    },
                };
            }
            case "VendorId":
            case "PreviewOffer": {
                const queryClientVendor = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const domainId = await getDomainId(
                    queryClientVendor,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_reviewWord",
                                "several_general_compareButton",

                                "several_general_visitWebsite",
                                "several_general_contents",
                                "several_vendor_sideBySideComparison",
                                "several_general_vs",
                                "several_general_share",
                                "several_general_sideWidget_title",
                                "several_ratingTooltipMessage",
                                "several_general_formLabelTopOfTheForm",
                                "several_vendorPage_quickStats",
                                "several_general_readInOurBlog",
                                "several_general_prosWord",
                                "several_general_consWord",

                                "several_general_freeQuotePopupTitle",
                                "several_general_freeQuotePopupDescription",
                                "several_general_freeQuotePopupButton",

                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_general_Read review",
                                "several_general-DiscountWord",
                                "several_offerPage-DevicesName",
                                "several_general-get-a-quote",
                                "several_general_meta_description",
                                "several_writersPage_writtenBy",
                                "several_writersPage_editedBy",
                                "several_general-by-word",
                                "several_general-more-article",
                            ],
                        },
                    ]),
                );
                const skipCache = page === "PreviewOffer" ? true : false;
                const offer = await offerByIdQuery(
                    queryClientVendor,
                    parseInt((params?.id as string) ?? "0"),
                    locale as string,
                    skipCache,
                    domainId,
                );

                const preDefinedListTypeAttributes = offer?.attributes
                    ?.filter(
                        (attribute) =>
                            attribute?.baseAttribute?.type === "PredefinedList",
                    )
                    .map((attribute) => {
                        let id: number = 0;
                        try {
                            const parsed = JSON.parse(
                                attribute?.value as string,
                            ) as {
                                [x: string]: string;
                            };

                            id = parseInt(parsed.id);
                        } catch (error) {
                            id = parseInt(attribute?.value as string, 10);
                        }
                        return id;
                    });

                if (
                    offer === null ||
                    !offer?.categories ||
                    offer?.categories?.length === 0 ||
                    offer.domains?.length === 0
                ) {
                    return {
                        notFound: true,
                    };
                }

                const promises = await Promise.all([
                    getAllDKIs(),
                    ReviewPageDomainOffersQuery(
                        offer.categories?.[0]?.id as number,
                        locale as string,
                        domainId,
                        skipCache,
                    ),
                    reviewGetCategoryByIdQuery(
                        offer.categories?.[0]?.id as number,
                        locale as string,
                    ),
                    preDefinedListTypeAttributes?.length
                        ? getLookupsByIds(
                              queryClientVendor,
                              preDefinedListTypeAttributes ?? [],
                              locale as string,
                          )
                        : null,
                ]);
                const dkis = promises[0];
                const domainOffers = promises[1];
                const category = promises[2];
                const lookups = promises[3];

                offer.attributes = getReplacedPredefinedListAttributes(
                    offer.attributes as GQLCategoryAttributesValues[],
                    lookups as GQLLookupsByIdsQuery,
                );

                if (category) {
                    queryClientVendor.setQueryData(
                        ["categoryUrl"],
                        category.url,
                    );
                    queryClientVendor.setQueryData(["categoryId"], category.id);
                    const activeForm: GQLDomainForms | undefined =
                        await activeFormQuery(
                            queryClientVendor,
                            category,
                            domainId,
                            skipCache,
                        );

                    queryClientVendor.setQueryData(
                        ["activeFormCategorySlug"],
                        category?.slug,
                    );
                    queryClientVendor.setQueryData(
                        ["activeFormCategoryId"],
                        category?.id,
                    );
                    queryClientVendor.setQueryData(
                        ["tcpaMessage"],
                        category?.TCPAMessage,
                    );

                    if (!activeForm) {
                        queryClientVendor.setQueryData(
                            ["activeFormCategorySlug"],
                            null,
                        );
                        queryClientVendor.setQueryData(
                            ["activeFormCategoryId"],
                            null,
                        );
                        queryClientVendor.setQueryData(["tcpaMessage"], null);
                        queryClientVendor.setQueryData(
                            ["activeForm", category.slug],
                            null,
                        );
                    }
                }

                if (category?.slug === "auto-warranty") {
                    await crossSellingData(
                        queryClientVendor,
                        category?.id as number,
                        domainId,
                        skipCache,
                    );
                }

                const allUrlsResponse = await getAllUrls(
                    queryClientVendor,
                    "offers",
                    offer.id?.toString() as string,
                    skipCache,
                );

                queryClientVendor.setQueryData(["offerBySlugParams"], params);
                queryClientVendor.setQueryData(["offerPageSharedProps"], {
                    activeTab: "review",
                });

                const pageProps: IReviewOfferPageProps = {
                    category: category as GQLCategories,
                    offers: domainOffers?.flatMap(
                        (item) => item?.offers,
                    ) as GQLOffers[],
                    dkis: dkis as GQLDkis[],
                    offer: {
                        ...offer,
                        // filter topics that don't have a translated version of the current language
                        topics: offer?.topics?.filter((topic) => {
                            return topic?.urls?.some(
                                (url) => url?.language === locale,
                            );
                        }),
                        attributes: offer?.attributes.filter(
                            (attribute) =>
                                attribute?.baseAttribute?.categoryId ===
                                category?.id,
                        ),
                    },
                };
                queryClientVendor.setQueryData(["reviewPageProps"], pageProps);
                const offerLanguages = offer?.languages as string[];
                //@ts-ignore
                const categoryLanguages = category?.languages as string[];
                generateAvailableUrls(
                    queryClientVendor,
                    offerLanguages,
                    offer.url,
                    resolveURL(category?.url as GQLUrls),
                    categoryLanguages,
                );
                if (page === "PreviewOffer") {
                    return {
                        props: {
                            pageType: "vendor",
                            vendor: offer.title,
                            vendorId: offer.id,
                            dehydratedState: dehydrate(queryClientVendor),
                            params,
                            title: allUrlsResponse.filter(
                                (item) =>
                                    item.language ==
                                    process.env.NEXT_PUBLIC_DEFAULT_LANG,
                            )[0].title,
                            url: resolveURL(offer.url as GQLUrls),
                        },
                    };
                }

                return {
                    props: {
                        pageType: "vendor",
                        vendor: offer.title,
                        vendorId: offer.id,
                        dehydratedState: dehydrate(queryClientVendor),
                        params,
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                process.env.NEXT_PUBLIC_DEFAULT_LANG,
                        )[0].title,
                        url: resolveURL(offer.url as GQLUrls),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "VendorCompareById": {
                const queryClientVendorCompare = new QueryClient(
                    DEFAULT_OPTIONS,
                );
                const { params } = ctx as GetStaticPropsContext;
                const vendorId = params?.id;
                const domainId = await getDomainId(
                    queryClientVendorCompare,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_reviewWord",
                                "several_general_compareButton",

                                "several_comparePage_pressAddCompare",
                                "several_comparePage_chooseProductsCompare",
                                "several_comparePage_chooseVendorsComparisons",
                                "several_comparePage_selectAll",
                                "several_comparePage_ItemsSelected",
                                "several_comparePage_addCompare",
                                "several_general_Read review",
                                "several_comparisons-quickStatsTitle",
                                "several_comparisons-quickOverviewTitle",
                                "several_general_sellingPointsWord",

                                "several_general_prosWord",
                                "several_general_consWord",
                                "several_general_visitWebsite",

                                "several_general_freeQuotePopupTitle",
                                "several_general_freeQuotePopupDescription",
                                "several_general_freeQuotePopupButton",

                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_comparisons-quickOverviewTitle",
                                "several_category_compare_page_tooltip",
                                "several_general_meta_description",
                                "several_comparePage_meta-title-1",
                                "several_comparePage_meta-title-2",
                            ],
                        },
                    ]),
                );
                const promises = await Promise.all([
                    GetVendorComparePageDomainOffer(
                        parseInt(vendorId as string),
                        locale as string,
                        domainId,
                    ),
                    allDkisQuery(queryClientVendorCompare),
                ]);
                const domainOffer = promises[0];
                const dkis = promises[1];
                const category = domainOffer?.offers?.[0]
                    ?.categories?.[0] as GQLCategories;
                delete domainOffer?.offers?.[0]?.categories;
                const offer = domainOffer?.offers?.[0];
                const preDefinedListTypeAttributes = offer?.attributes
                    ?.filter((attribute) => {
                        return (
                            attribute?.baseAttribute?.type === "PredefinedList"
                        );
                    })
                    .map((attribute) => {
                        let id: number = 0;
                        try {
                            const parsed = JSON.parse(
                                attribute?.value as string,
                            ) as {
                                [x: string]: string;
                            };

                            id = parseInt(parsed.id);
                        } catch (error) {
                            id = parseInt(attribute?.value as string, 10);
                        }
                        return id;
                    });
                let lookups: GQLLookupsByIdsQuery | undefined = undefined;

                if (preDefinedListTypeAttributes?.length) {
                    lookups = await getLookupsByIds(
                        queryClientVendorCompare,
                        preDefinedListTypeAttributes ?? [],
                        locale as string,
                    );
                }

                if (lookups && offer) {
                    offer.attributes = getReplacedPredefinedListAttributes(
                        offer?.attributes as GQLCategoryAttributesValues[],
                        lookups,
                    );
                }

                if (!offer) {
                    return {
                        notFound: true,
                    };
                }
                const vendorsToCompare = await vendorCompareGetVendorsToCompare(
                    category.id as number,
                    domainId,
                );

                const pageProps: IVendorComparePageProps = {
                    category: category,
                    offer: {
                        ...offer,
                        attributes: offer.attributes?.filter(
                            (attribute) =>
                                attribute?.baseAttribute?.categoryId ===
                                category.id,
                        ),
                    },
                    dkis: dkis,
                    vendorsToCompare: vendorsToCompare?.flatMap(
                        (item) => item?.offers,
                    ) as GQLOffers[],
                };

                queryClientVendorCompare.setQueryData(
                    ["activePage"],
                    "categories",
                );
                queryClientVendorCompare.setQueryData(
                    ["vendorComparePageProps"],
                    pageProps,
                );
                queryClientVendorCompare.setQueryData(
                    ["offerPageSharedProps"],
                    {
                        activeTab: "compare",
                    },
                );
                //@ts-ignore
                const offerLanguages = offer?.languages as string[];
                //@ts-ignore
                const categoryLanguages = category?.languages as string[];
                generateAvailableUrls(
                    queryClientVendorCompare,
                    offerLanguages,
                    offer.url,
                    resolveURL(category?.url as GQLUrls),
                    categoryLanguages,
                );
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientVendorCompare),
                    },
                };
            }
            case "Blog": {
                const queryClientBlog = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientBlog,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_blogWord",
                                "several_general_discoverTopBlogs",
                                "several_blogPage_fromBeautyMindfulness",
                                "several_general_category_select_placeholder",
                                "several_general_search",
                                "several_blogPage_lastArticlesWord",
                                "several_general_readMoreButton",
                                "several_general_showMoreButton",
                                "several_blogPage_meta_title",
                                "several_blogPage_meta_description",
                            ],
                        },
                    ]),
                );
                const categories = await allCategoriesSlugs(
                    queryClientBlog,
                    domainId,
                );
                const allCategoriesPosts: GQLCategories[] = [];
                categories?.forEach((categoryElem) => {
                    allCategoriesPosts.push(
                        getCategoryQuery(
                            categoryElem,
                            domainId,
                        ) as GQLCategories,
                    );
                });

                await allPostsByCategoriesSlugs(
                    queryClientBlog,
                    `{${allCategoriesPosts?.join(",\n")}}`,
                );

                await Promise.all([
                    allPostsByCategoriesSlugs(
                        queryClientBlog,
                        `{${allCategoriesPosts?.join(",\n")}}`,
                    ),

                    lastArticlesQuery(queryClientBlog, domainId),
                ]);
                queryClientBlog.setQueryData(["activePage"], "blog");
                generateAvailableUrls(queryClientBlog, LANGUAGES, "/blog");
                return {
                    props: {
                        pageType: "blog",
                        dehydratedState: dehydrate(queryClientBlog),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "News": {
                const queryClientNews = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientNews,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_newsWord",
                                "several_newsPage_meta_title",
                                "several_newsPage_meta_description",
                                "several_general_readMoreButton",
                                "several_news_featuredPost",
                                "several_news_newsCategories",
                                "several_news_readMoreNews",
                                "several_general_seeMore",
                                "several_news_popularToday",
                                "several_news_latestNews",
                                "several_news_trending",
                                "several_news_inCaseYouMissedIt",
                                "several_general_showMoreButton",
                            ],
                        },
                    ]),
                );
                const promises = await Promise.all([
                    featuredNewsQuery(domainId),
                    newsByTagsQuery(domainId),
                    fetchTerndingNews(domainId),
                    fetchMissedNews(domainId),
                ]);
                const featuredNews = promises[0];
                const newsByTags = promises[1];
                const trendingNews = promises[2];
                const missedNews = promises[3];
                queryClientNews.setQueryData(["featuredNews"], featuredNews);
                queryClientNews.setQueryData(["trendingNews"], trendingNews);
                queryClientNews.setQueryData(["newsByTags"], newsByTags);
                queryClientNews.setQueryData(["missedNews"], missedNews);
                queryClientNews.setQueryData(["activePage"], "news");
                generateAvailableUrls(queryClientNews, LANGUAGES, "/news");
                return {
                    props: {
                        pageType: "news",
                        dehydratedState: dehydrate(queryClientNews),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "BlogId": {
                const queryClientBlogPost = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientBlogPost,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_contents",
                                "several_general_recentPosts",
                                "several_general_share",
                                "several_general_formLabelTopOfTheForm",
                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_general_sideWidget_title",
                                "several_blogPostPage_relatedTopics",
                                "several_general-get-a-quote",
                                "several_general_meta_description",
                                "several_general_blogWord",
                                "several_writersPage_writtenBy",
                                "several_writersPage_editedBy",
                                "several_general-by-word",
                                "several_general-more-article",
                            ],
                        },
                    ]),
                );

                const { params } = ctx;
                const promise = await Promise.all([
                    postGetPostByIdQuery(params?.id as string, domainId),
                    getAllUrls(
                        queryClientBlogPost,
                        "posts",
                        params?.id as string,
                    ),
                    getAllDKIs(),
                ]);
                const post: GQLPosts | undefined = promise[0];
                const dkis = promise[2];

                if (!post || !post.categoryId) {
                    return {
                        notFound: true,
                    };
                }
                let category: GQLCategories | undefined =
                    await postGetCategoryById(
                        post.categoryId,
                        locale as string,
                        domainId,
                    );
                if (post && post.categoryId) {
                    category = await postGetCategoryById(
                        post.categoryId,
                        locale as string,
                        domainId,
                    );

                    const activeForm: GQLDomainForms | undefined =
                        await activeFormQuery(
                            queryClientBlogPost,
                            category,
                            domainId,
                        );
                    if (category?.slug === "auto-warranty") {
                        await crossSellingData(
                            queryClientBlogPost,
                            category?.id as number,
                            domainId,
                        );
                    }
                    queryClientBlogPost.setQueryData(
                        ["activeFormCategorySlug"],
                        category?.slug,
                    );
                    queryClientBlogPost.setQueryData(
                        ["activeFormCategoryId"],
                        category?.id,
                    );
                    queryClientBlogPost.setQueryData(
                        ["tcpaMessage"],
                        category?.TCPAMessage,
                    );

                    if (!activeForm) {
                        queryClientBlogPost.setQueryData(
                            ["activeFormCategorySlug"],
                            null,
                        );
                        queryClientBlogPost.setQueryData(
                            ["activeFormCategoryId"],
                            null,
                        );
                        queryClientBlogPost.setQueryData(["tcpaMessage"], null);
                        queryClientBlogPost.setQueryData(
                            ["activeForm", category?.slug ?? null],
                            null,
                        );
                    }
                }

                // delete posts inside category if posts already has related posts so we don't cache unnecessary data
                const relatedPosts = post.related?.length
                    ? post.related
                    : category?.posts ?? [];

                if (post?.related && post?.related?.length)
                    delete category?.posts;
                const pageProps: IBlogPostPageProps = {
                    category: category ?? {},
                    post: post,
                    relatedPosts: relatedPosts?.map((item) => ({
                        title: item?.title as string,
                        link: resolveURL(item?.url as GQLUrls),
                    })),
                    dkis: dkis as GQLDkis[],
                };
                queryClientBlogPost.setQueryData(["postByIdParams"], params);
                queryClientBlogPost.setQueryData(["activePage"], "blog");
                queryClientBlogPost.setQueryData(
                    ["blogPostPageProps"],
                    pageProps,
                );

                const allUrlsResponse = promise[1];
                generateAvailablePostUrls(
                    queryClientBlogPost,
                    post.urls as GQLUrls[],
                    "/blog",
                );
                return {
                    props: {
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                    process.env.NEXT_PUBLIC_DEFAULT_LANG &&
                                item.isMain,
                        )[0].title,
                        dehydratedState: dehydrate(queryClientBlogPost),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "NewsId": {
                const queryClientBlogPost = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientBlogPost,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_contents",
                                "several_general_recentPosts",
                                "several_general_share",
                                "several_general_formLabelTopOfTheForm",
                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_general_sideWidget_title",
                                "several_blogPostPage_relatedTopics",
                                "several_general-get-a-quote",
                                "several_general_meta_description",
                                "several_general_newsWord",
                                "several_writersPage_writtenBy",
                                "several_writersPage_editedBy",
                                "several_general-by-word",
                                "several_general-more-article",
                            ],
                        },
                    ]),
                );
                const { params } = ctx;
                const promise = await Promise.all([
                    postGetPostByIdQuery(params?.id as string, domainId),
                    getAllUrls(
                        queryClientBlogPost,
                        "posts",
                        params?.id as string,
                    ),
                    getAllDKIs(),
                ]);
                const post: GQLPosts | undefined = promise[0];
                const dkis = promise[2];

                if (!post || !post.categoryId) {
                    return {
                        notFound: true,
                    };
                }
                let category: GQLCategories | undefined =
                    await postGetCategoryById(
                        post.categoryId,
                        locale as string,
                        domainId,
                    );

                if (post && post.categoryId) {
                    category = await postGetCategoryById(
                        post.categoryId,
                        locale as string,
                        domainId,
                    );

                    const activeForm: GQLDomainForms | undefined =
                        await activeFormQuery(
                            queryClientBlogPost,
                            category,
                            domainId,
                        );
                    if (category?.slug === "auto-warranty") {
                        await crossSellingData(
                            queryClientBlogPost,
                            category?.id as number,
                            domainId,
                        );
                    }
                    queryClientBlogPost.setQueryData(
                        ["activeFormCategorySlug"],
                        category?.slug,
                    );
                    queryClientBlogPost.setQueryData(
                        ["activeFormCategoryId"],
                        category?.id,
                    );
                    queryClientBlogPost.setQueryData(
                        ["tcpaMessage"],
                        category?.TCPAMessage,
                    );

                    if (!activeForm) {
                        queryClientBlogPost.setQueryData(
                            ["activeFormCategorySlug"],
                            null,
                        );
                        queryClientBlogPost.setQueryData(
                            ["activeFormCategoryId"],
                            null,
                        );
                        queryClientBlogPost.setQueryData(["tcpaMessage"], null);
                        queryClientBlogPost.setQueryData(
                            ["activeForm", category?.slug ?? null],
                            null,
                        );
                    }
                }

                // delete posts inside category if posts already has related posts so we don't cache unnecessary data
                const relatedPosts = post.related?.length
                    ? post.related
                    : category.posts;

                if (post?.related && post?.related?.length)
                    delete category?.posts;
                const pageProps: IBlogPostPageProps = {
                    category: category ?? {},
                    post: post,
                    relatedPosts: relatedPosts?.map((item) => ({
                        title: item?.title as string,
                        link: resolveURL(item?.url as GQLUrls),
                    })),
                    dkis: dkis as GQLDkis[],
                };
                queryClientBlogPost.setQueryData(["postByIdParams"], params);
                queryClientBlogPost.setQueryData(["activePage"], "news");
                queryClientBlogPost.setQueryData(
                    ["blogPostPageProps"],
                    pageProps,
                );

                const allUrlsResponse = promise[1];
                generateAvailablePostUrls(
                    queryClientBlogPost,
                    post.urls as GQLUrls[],
                    "/news",
                );
                return {
                    props: {
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                    process.env.NEXT_PUBLIC_DEFAULT_LANG &&
                                item.isMain,
                        )[0].title,
                        dehydratedState: dehydrate(queryClientBlogPost),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "Preview": {
                const queryClientBlogPost = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientBlogPost,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_contents",
                                "several_general_recentPosts",
                                "several_general_share",
                                "several_general_formLabelTopOfTheForm",
                                "several_general_formModalText",
                                "several_general_formModalBackPreviousPage",
                                "several_general_sideWidget_title",
                                "several_general-get-a-quote",
                                "several_general_meta_description",
                            ],
                        },
                    ]),
                );
                const { params } = ctx;
                const promise = await Promise.all([
                    postByIdQuery(
                        queryClientBlogPost,
                        params?.id as string,
                        true,
                    ),
                    allCategoriesQuery(queryClientBlogPost, domainId, true),
                    getAllUrls(
                        queryClientBlogPost,
                        "posts",
                        params?.id as string,
                        true,
                    ),
                ]);

                const post: GQLPosts | undefined = promise[0];
                const allCategories = promise[1];
                const category = allCategories?.find(
                    (category) => category.id === post?.categoryId,
                );
                if (!post) {
                    return {
                        notFound: true,
                    };
                }
                if (post && post.categoryId && category) {
                    await recentPostsByCategoryQuery(
                        queryClientBlogPost,
                        domainId,
                        post?.categoryId,
                        category?.slug as string,
                        true,
                    );

                    const activeForm: GQLDomainForms | undefined =
                        await activeFormQuery(
                            queryClientBlogPost,
                            category,
                            domainId,
                            true,
                        );
                    if (category?.slug === "auto-warranty") {
                        await crossSellingData(
                            queryClientBlogPost,
                            category?.id as number,
                            domainId,
                            true,
                        );
                    }
                    queryClientBlogPost.setQueryData(
                        ["activeFormCategorySlug"],
                        category?.slug,
                    );
                    queryClientBlogPost.setQueryData(
                        ["activeFormCategoryId"],
                        category?.id,
                    );
                    queryClientBlogPost.setQueryData(
                        ["tcpaMessage"],
                        category?.TCPAMessage,
                    );

                    if (!activeForm) {
                        queryClientBlogPost.setQueryData(
                            ["activeFormCategorySlug"],
                            null,
                        );
                        queryClientBlogPost.setQueryData(
                            ["activeFormCategoryId"],
                            null,
                        );
                        queryClientBlogPost.setQueryData(["tcpaMessage"], null);
                        queryClientBlogPost.setQueryData(
                            ["activeForm", category?.slug],
                            null,
                        );
                    }
                } else {
                    await recentPostsQuery(queryClientBlogPost, domainId, true);
                }
                queryClientBlogPost.setQueryData(["postByIdParams"], params);
                queryClientBlogPost.setQueryData(["activePage"], "blog");

                const allUrlsResponse = promise[2];

                return {
                    props: {
                        title: allUrlsResponse.filter(
                            (item) =>
                                item.language ==
                                process.env.NEXT_PUBLIC_DEFAULT_LANG,
                        )[0].title,
                        dehydratedState: dehydrate(queryClientBlogPost),
                        blog: post,
                    },
                };
            }
            case "CategorySlug": {
                const queryClientCategorySlug = new QueryClient(
                    DEFAULT_OPTIONS,
                );
                const { params } = ctx;
                const domainId = await getDomainId(
                    queryClientCategorySlug,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_blogWord",
                                "several_general_discoverTopBlogs",
                                "several_blogPage_fromBeautyMindfulness",
                                "several_general_readMoreButton",
                                "several_general_search",
                                "several_general_loading",
                                "several_siteMapPage_Blogs",
                                "several_general_meta_description",
                            ],
                        },
                    ]),
                );
                await Promise.all([
                    categorySlugPaginationQuery(
                        queryClientCategorySlug,
                        params?.categorySlug as string,
                        domainId,
                    ),

                    allCategoriesCategorySlug(
                        queryClientCategorySlug,
                        domainId,
                    ),
                ]);
                // @ts-ignore
                const { data } = queryClientCategorySlug.getQueryState([
                    "postsByCategorySlug",
                    {
                        limit: BLOGS_PER_PAGE_GENERAL,
                        offset: 0,
                        domainId: domainId,
                        slug: params?.categorySlug as string,
                        language: locale,
                    },
                ]);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const languages = (data?.pages?.[0]?.category
                    ?.languages as string[]) ?? ["en"];
                generateAvailableUrls(
                    queryClientCategorySlug,
                    languages,
                    `/blog/category/${params?.categorySlug as string}`,
                    "/blog",
                );

                return {
                    props: {
                        dehydratedState: dehydrate(queryClientCategorySlug),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "CategorySlugIndex": {
                const queryClientCategorySlugIndex = new QueryClient(
                    DEFAULT_OPTIONS,
                );
                const domainId = await getDomainId(
                    queryClientCategorySlugIndex,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_general_blogWord",
                                "several_general_discoverTopBlogs",
                                "several_blogPage_fromBeautyMindfulness",
                                "several_general_readMoreButton",
                                "several_general_search",
                                "several_general_loading",
                                "several_general_chooseTopicsWord",
                                "several_blogCategory_select_a_category",
                                "several_general_meta_description",
                            ],
                        },
                    ]),
                );
                await Promise.all([
                    allCategoriesCategorySlug(
                        queryClientCategorySlugIndex,
                        domainId,
                    ),
                ]);
                queryClientCategorySlugIndex.setQueryData(
                    ["activePage"],
                    "categories",
                );
                generateAvailableUrls(
                    queryClientCategorySlugIndex,
                    LANGUAGES,
                    "/blog/category",
                );

                return {
                    props: {
                        dehydratedState: dehydrate(
                            queryClientCategorySlugIndex,
                        ),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "AboutUs": {
                const queryClientAboutUs = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientAboutUs,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_aboutUsPage_AboutUsTitle",
                                "several_aboutUsPage_AboutUsDescription",
                                "several_aboutUsPage_digitalWorldDescription",
                                "several_aboutUsPage_digitalWorldTitle",

                                "several_aboutUsPage_believeInUsTitle",
                                "several_aboutUsPage_believeInUsDescription",
                                "several_aboutUsPage_HonestyTitle",
                                "several_aboutUsPage_HonestyDescription",

                                "several_aboutUsPage_CommitmentTitle",
                                "several_aboutUsPage_CommitmentDescription",

                                "several_aboutUsPage_DeterminationTitle",
                                "several_aboutUsPage_DeterminationDescription",

                                "several_aboutUsPage_OurTeamComprisesDescription",
                                "several_aboutUsPage_OurTeamComprisesTitle",

                                "several_general_whyWord",
                                "several_aboutUsPage_WeAreIndependentTitle",
                                "several_aboutUsPage_WeAreIndependentDescription",

                                "several_aboutUsPage_OnlyFactBasedInformationTitle",
                                "several_aboutUsPage_OnlyFactBasedInformationDescription",

                                "several_aboutUsPage_SimpleAndStraightforwardTitle",
                                "several_aboutUsPage_SimpleAndStraightforwardDescription",

                                "several_aboutUsPage_ConsBeforeProsTitle",
                                "several_aboutUsPage_ConsBeforeProsDescription",

                                "several_general_readInOurBlog",
                                "several_aboutUsPage_meta_title",
                                "several_aboutUsPage_meta_description",
                            ],
                        },
                    ]),
                );
                await Promise.all([
                    recentPostsQuery(queryClientAboutUs, domainId),
                ]);
                generateAvailableUrls(
                    queryClientAboutUs,
                    LANGUAGES,
                    "/about-us",
                );

                return {
                    props: {
                        pageType: "aboutUs",
                        dehydratedState: dehydrate(queryClientAboutUs),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "ContactUs": {
                const queryClientStaticPages = new QueryClient(DEFAULT_OPTIONS);
                queryClientStaticPages.setQueryData(
                    ["activePage"],
                    "contact us",
                );
                await getDomainId(
                    queryClientStaticPages,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_contactUS_mainTitle",
                                "several_contactUS_mainDescription",
                                "several_partnerWithUsPage_letsTalk",
                                "several_contactUS_mainFormDescription",
                                "several_contactUS_formPlaceHolderFirstName",
                                "several_contactUS_formPlaceHolderLastName",
                                "several_contactUS_formPlaceHolderEmail",
                                "several_contactUS_formPlaceHolderPhone",
                                "several_contactUS_formPlaceHolderSubject",
                                "several_category_sideWidget_description",
                                "several_partnerWithUsPage_formPlaceholderWouldLikeUsKnow",
                                "several_contactUS_mainTitle",
                                "several_contactUsPage_mapMainTitle",
                                "several_partnerWithUsPage_formSubmitThankYou",
                                "several_partnerWithUsPage_formSubmitYourMessageSent",
                                "several_partnerWithUsPage_formSubmitOurSupportStaff",
                                "several_contactUS_meta_description",
                            ],
                        },
                    ]),
                );
                generateAvailableUrls(
                    queryClientStaticPages,
                    LANGUAGES,
                    "/contact-us",
                );
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientStaticPages),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "PartnerWithUs": {
                const queryClientStaticPages = new QueryClient(DEFAULT_OPTIONS);
                await getDomainId(
                    queryClientStaticPages,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_partnerWithUsPage_mainTitle",
                                "several_partnerWithUsPage_mainDescription",
                                "several_partnerWithUsPage_letsTalk",
                                "several_partnerWithUsPage_WhoWeAreTitle",
                                "several_partnerWithUsPage_WhoWeAreDescription",
                                "several_partnerWithUsPage_WhatWeDoTitle",
                                "several_partnerWithUsPage_WhatWeDoDescription",
                                "several_partnerWithUsPage_HowWeDoItTitle",
                                "several_partnerWithUsPage_HowWeDoItDescription",
                                "several_partnerWithUsPage_industriesTitle",
                                "several_partnerWithUsPage_LifestyleAndTechTitle",
                                "several_partnerWithUsPage_BusinessServicesTitle",
                                "several_partnerWithUsPage_FinancialServicesTitle",
                                "several_partnerWithUsPage_BecomeAPartnerTitle",
                                "several_partnerWithUsPage_formLabelFirstName",
                                "several_partnerWithUsPage_formPlaceholderFirstName",
                                "several_partnerWithUsPage_formLabelLastName",
                                "several_partnerWithUsPage_formPlaceholderLastName",
                                "several_partnerWithUsPage_formLabelEmail",
                                "several_partnerWithUsPage_formPlaceholderEmail",
                                "several_partnerWithUsPage_formLabelPhoneNumber",
                                "several_partnerWithUsPage_formPlaceholderPhoneNumber",
                                "several_partnerWithUsPage_formLabelPosition",
                                "several_partnerWithUsPage_formPlaceholderPosition",
                                "several_partnerWithUsPage_formLabelCompanyName",
                                "several_partnerWithUsPage_formPlaceholderCompanyName",
                                "several_partnerWithUsPage_formLabelCompanySize",
                                "several_partnerWithUsPage_formPlaceholderCompanySize",
                                "several_partnerWithUsPage_formLabelUseFollowing",
                                "several_partnerWithUsPage_formLabelNativeAdvertising",
                                "several_partnerWithUsPage_formLabelComparisonSites",
                                "several_partnerWithUsPage_formLabelPPCAdvertising",
                                "several_partnerWithUsPage_formLabelWouldLikeUsKnow",
                                "several_partnerWithUsPage_formPlaceholderWouldLikeUsKnow",
                                "several_partnerWithUsPage_formSend",
                                "several_partnerWithUsPage_formErrorFirstName",
                                "several_partnerWithUsPage_formErrorLastName",
                                "several_partnerWithUsPage_formErrorEmailRequired",
                                "several_partnerWithUsPage_formErrorPhoneNumberFieldRequired",
                                "several_partnerWithUsPage_formErrorCompanyNameRequired",
                                "several_partnerWithUsPage_formSubmitThankYou",
                                "several_partnerWithUsPage_formSubmitYourMessageSent",
                                "several_partnerWithUsPage_formSubmitOurSupportStaff",
                                "several_siteMapPage_PartnerWithUs",
                                "several_partnerWithUsPage_meta_description",
                            ],
                        },
                    ]),
                );
                generateAvailableUrls(
                    queryClientStaticPages,
                    LANGUAGES,
                    "/partner-with-us",
                );
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientStaticPages),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "PrivacyPolicy": {
                const queryClientStaticPages = new QueryClient(DEFAULT_OPTIONS);

                await getDomainId(
                    queryClientStaticPages,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_privacyPolicyPage_PrivacyPolicy",
                                "several_privacyPolicyPage_meta_description",
                            ],
                        },
                    ]),
                );
                generateAvailableUrls(
                    queryClientStaticPages,
                    LANGUAGES,
                    "/privacy-policy",
                );
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientStaticPages),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "WhatWeDo": {
                const queryClientStaticPages = new QueryClient(DEFAULT_OPTIONS);

                await getDomainId(
                    queryClientStaticPages,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_whatWeDo-mainTitlePage",
                                "several_whatWeDo-simplifyingComplexTitle",
                                "several_whatWeDo-simplifyingComplexDescription1",
                                "several_whatWeDo-simplifyingComplexDescription2",
                                "several_whatWeDo-simplifyingTransparencyTitle",
                                "several_whatWeDo-transparencyDescription",
                                "several_whatWeDo-reviewProcess",
                                "several_whatWeDo-reviewProcessDescription",
                                "several_whatWeDo-reviewProcessDescription2",
                                "several_whatWeDo-expertiseResearch",
                                "several_whatWeDo-expertiseResearchDescription1",
                                "several_whatWeDo-expertiseResearchDescription2",
                                "several_whatWeDo-partnerRelationshipsTiltle",
                                "several_whatWeDo-partnerRelationshipsDescription",
                                "several_whatWeDo-makeMoneyTitle",
                                "several_whatWeDo-makeMoneyDescription1",
                                "several_whatWeDo-makeMoneyDescription2",
                                "several_whatWeDo-userSatisfactionTitle",
                                "several_whatWeDo-userSatisfactionDescription1",
                                "several_whatWeDo-trustReliabilityTitle",
                                "several_whatWeDo-userSatisfactionDescription2",
                                "several_whatWeDo-ratingSystemTitle",
                                "several_whatWeDo-ratingSystemDescription1",
                                "several_whatWeDo-ratingSystemDescription2",
                                "several_whatWeDo-customerExperienceTitle",
                                "several_whatWeDo-customerExperienceDescription",
                                "several_whatWeDo-pricingTitle",
                                "several_whatWeDo-pricingDescription",
                                "several_whatWeDo-featuresTitle",
                                "several_whatWeDo-featuresDescription",
                                "several_whatWeDo-reputationTitle",
                                "several_whatWeDo-reputationDescription",
                                "several_whatWeDo-clarityTitle",
                                "several_whatWeDo-clarityDescription",
                                "several_whatWeDo-ourAimIsDescription",
                                "several_whatWeDoFaq-mileageRestrictionTitle",
                                "several_whatWeDoFaq-mileageRestrictionDescription",
                                "several_whatWeDoFaq-productsOrServicesTitle",
                                "several_whatWeDoFaq-productsOrServicesDescription",
                                "several_whatWeDoFaq-reviewsAndRatingsTitle",
                                "several_whatWeDoFaq-reviewsAndRatingsDescription",
                                "several_whatWeDoFaq-companiesTrustworthyTitle",
                                "several_whatWeDoFaq-companiesTrustworthyDescription",
                                "several_whatWeDoFaq-personalInformationSafeTitle",
                                "several_whatWeDoFaq-personalInformationSafeDescription",
                                "several_whatWeDoFaq-chargesAssociatedTitle",
                                "several_whatWeDoFaq-chargesAssociatedDescription",
                                "several_whatWeDoFaq-frequentlyWebsiteUpdatedTitle",
                                "several_whatWeDoFaq-frequentlyWebsiteUpdatedDescription",
                                "several_whatWeDoFaq-differenceBetweenTitle",
                                "several_whatWeDoFaq-differenceBetweenDescription",
                                "several_whatWeDoFaq-matchPeopleTitle",
                                "several_whatWeDoFaq-matchPeopleDescription",
                                "several_whatWeDoFaq-privacyPolicyTitle",
                                "several_whatWeDoFaq-privacyPolicyDescription",
                                "several_aboutUsPage_meta_title", //same meta title as about us page
                                "several_aboutUsPage_meta_description", //same meta description as about us page
                            ],
                        },
                    ]),
                );
                generateAvailableUrls(
                    queryClientStaticPages,
                    LANGUAGES,
                    "/what-we-do",
                );
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientStaticPages),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "SiteMap": {
                const queryClientSiteMap = new QueryClient(DEFAULT_OPTIONS);
                const domainId = await getDomainId(
                    queryClientSiteMap,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_siteMapPage_SiteMap",
                                "several_siteMapPage_Vendors",
                                "several_siteMapPage_Blogs",
                                "several_siteMapPage_Pages",
                                "several_siteMapPage_PartnerWithUs",
                                "several_privacyPolicyPage_PrivacyPolicy",
                                "several_aboutUsPage_AboutUsTitle",
                                "several_contactUS_mainTitle",
                                "several_siteMapPage_HomePage",
                                "several_siteMapPage_meta_description",
                            ],
                        },
                    ]),
                );
                const promises = await Promise.all([
                    allPostsSiteMapQuery(domainId),
                    sitemapGetAllCategories(domainId),
                    sitemapGetAllOffers(domainId),
                ]);
                const pageProps: ISitemapPageProps = {
                    posts: promises[0].filter((item) => item?.url),
                    categories: promises[1]
                        .map((item) => item?.category)
                        .filter((item) => item !== null) as GQLCategories[],
                    offers: promises[2].flatMap(
                        (item) => item?.offers,
                    ) as GQLOffers[],
                };
                queryClientSiteMap.setQueryData(
                    ["sitemapPageProps"],
                    pageProps,
                );

                generateAvailableUrls(
                    queryClientSiteMap,
                    LANGUAGES,
                    "/site-map",
                );

                return {
                    props: {
                        dehydratedState: dehydrate(queryClientSiteMap),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "TagSlug": {
                const queryClientTagSlug = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const domainId = await getDomainId(
                    queryClientTagSlug,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_siteMapPage_SiteMap",
                                "several_siteMapPage_Vendors",
                                "several_siteMapPage_Blogs",
                                "several_siteMapPage_Pages",
                                "several_general_discoverTopBlogs",
                                "several_general_blogWord",
                                "several_general_readMoreButton",
                                "several_general_loading",
                                "several_general_meta_description",
                                "several_articlesPage_failedMessage",
                            ],
                        },
                    ]),
                );

                const promise = await Promise.all([
                    tagSlugPaginationQuery(
                        queryClientTagSlug,
                        params?.tagSlug as string,
                        domainId,
                        2295,
                    ),
                    allTagsQuery(queryClientTagSlug),
                ]);

                // Promise.allSettled

                const allTags = promise[1];
                if (!allTags.find((tag) => tag.slug === params?.tagSlug)) {
                    return {
                        notFound: true,
                    };
                }
                generateAvailableUrls(
                    queryClientTagSlug,
                    LANGUAGES,
                    `/blog/tag/${params?.tagSlug as string}`,
                );

                return {
                    props: {
                        dehydratedState: dehydrate(queryClientTagSlug),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "NewsTagSlug": {
                const queryClientTagSlug = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const currentPage = parseInt(
                    (params?.page?.[0] as string) ?? "1",
                );
                const tagSlug = params?.tagSlug as string;
                if (isNaN(currentPage) || currentPage === 0) {
                    return {
                        redirect: {
                            destination: `/news/tag/${tagSlug}`,
                            permanent: true,
                        },
                    };
                }
                const domainId = await getDomainId(
                    queryClientTagSlug,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_siteMapPage_SiteMap",
                                "several_siteMapPage_Vendors",
                                "several_siteMapPage_Blogs",
                                "several_siteMapPage_Pages",
                                "several_general_discoverTopBlogs",
                                "several_general_blogWord",
                                "several_general_readMoreButton",
                                "several_general_loading",
                                "several_general_meta_description",
                                "several_articlesPage_failedMessage",
                                "several_general_newsWord",
                                "several_general_search",
                            ],
                        },
                    ]),
                );
                queryClientTagSlug.setQueryData(["activePage"], "news");

                const promise = await Promise.all([
                    newsByTagPaginationQuery(
                        tagSlug,
                        domainId,
                        2296,
                        currentPage - 1,
                    ),
                    fetchTotalNewsByTag(tagSlug, domainId),
                    tagsOptionsQuery(domainId),
                    allTagsQuery(queryClientTagSlug),
                ]);
                const news = promise[0];
                const total = promise[1];
                const tagOption = promise[2];
                const allTags = promise[3];

                if (!allTags.find((tag) => tag.slug === params?.tagSlug)) {
                    return {
                        notFound: true,
                    };
                }

                queryClientTagSlug.setQueryData(["newsByTagPagination"], news);
                queryClientTagSlug.setQueryData(["newsByTagTotal"], total);
                queryClientTagSlug.setQueryData(["tagOption"], tagOption);
                queryClientTagSlug.setQueryData(
                    ["selectedTag"],
                    tagOption?.find((item) => item.slug === tagSlug),
                );
                queryClientTagSlug.setQueryData(
                    ["newsByTagCurrentPage"],
                    currentPage,
                );

                generateAvailableUrls(
                    queryClientTagSlug,
                    LANGUAGES,
                    `/news/tag`,
                );

                return {
                    props: {
                        dehydratedState: dehydrate(queryClientTagSlug),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "allNewsTagsPage": {
                const queryClientAllNewsTagSlug = new QueryClient(
                    DEFAULT_OPTIONS,
                );
                const { params } = ctx;
                const currentPage = parseInt(
                    (params?.page?.[0] as string) ?? "1",
                );
                if ((isNaN(currentPage) || currentPage === 1) && params) {
                    return {
                        redirect: {
                            destination: `/news/tags`,
                            permanent: false,
                        },
                    };
                }
                const domainId = await getDomainId(
                    queryClientAllNewsTagSlug,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_siteMapPage_SiteMap",
                                "several_siteMapPage_Vendors",
                                "several_siteMapPage_Blogs",
                                "several_siteMapPage_Pages",
                                "several_general_discoverTopBlogs",
                                "several_general_blogWord",
                                "several_general_readMoreButton",
                                "several_general_loading",
                                "several_general_meta_description",
                                "several_articlesPage_failedMessage",
                                "several_general_newsWord",
                                "several_general_search",
                                "several_general_showMoreButton",
                            ],
                        },
                    ]),
                );
                queryClientAllNewsTagSlug.setQueryData(["activePage"], "news");

                const promise = await Promise.all([
                    fetchAllNewsPaginationQuery(
                        domainId,
                        2296,
                        currentPage - 1,
                    ),
                    fetchTotalNews(domainId),
                    tagsOptionsQuery(domainId),
                ]);
                const news = promise[0];
                const total = promise[1];
                const tagOption = promise[2];

                queryClientAllNewsTagSlug.setQueryData(
                    ["newsByTagPagination"],
                    news,
                );
                queryClientAllNewsTagSlug.setQueryData(
                    ["newsByTagTotal"],
                    total,
                );
                queryClientAllNewsTagSlug.setQueryData(
                    ["tagOption"],
                    tagOption,
                );
                queryClientAllNewsTagSlug.setQueryData(
                    ["newsByTagCurrentPage"],
                    currentPage,
                );

                generateAvailableUrls(
                    queryClientAllNewsTagSlug,
                    LANGUAGES,
                    `/news/tag`,
                );

                return {
                    props: {
                        dehydratedState: dehydrate(queryClientAllNewsTagSlug),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "WritersPage": {
                const queryClientWritersPage = new QueryClient(DEFAULT_OPTIONS);
                const { params } = ctx;
                const userSlug = params?.userName as string;
                const user = await fetchUserBySlug(userSlug);
                if (!user) {
                    return {
                        notFound: true,
                    };
                }

                const currentPage = parseInt((params?.page as string) ?? "1");

                if ((isNaN(currentPage) || params?.page === "1") && params) {
                    return {
                        redirect: {
                            destination: `/writers/${userSlug}`,
                            permanent: false,
                        },
                    };
                }
                const domainId = await getDomainId(
                    queryClientWritersPage,
                    getTranslationKeys([
                        {
                            operation: "keyIn",
                            value: [
                                "several_general_breadcrumb_home",
                                "several_siteMapPage_SiteMap",
                                "several_siteMapPage_Vendors",
                                "several_siteMapPage_Blogs",
                                "several_siteMapPage_Pages",
                                "several_general_discoverTopBlogs",
                                "several_general_blogWord",
                                "several_general_readMoreButton",
                                "several_general_loading",
                                "several_general_meta_description",
                                "several_articlesPage_failedMessage",
                                "several_general_newsWord",
                                "several_general_search",
                                "several_general_showMoreButton",
                                "several_writersPage_posted_by",
                            ],
                        },
                    ]),
                );

                const promise = await Promise.all([
                    fetchAllPostsPaginationQuery(
                        domainId,
                        currentPage - 1,
                        user.id as string,
                    ),
                    fetchTotalWritersPosts(domainId, user.id as string),
                ]);
                const posts = promise[0];
                const total = promise[1];

                queryClientWritersPage.setQueryData(
                    ["writerPostsPagination"],
                    posts,
                );
                queryClientWritersPage.setQueryData(
                    ["writerPostsTotal"],
                    total,
                );

                queryClientWritersPage.setQueryData(
                    ["writerPostsCurrentPage"],
                    currentPage,
                );
                queryClientWritersPage.setQueryData(["writerPostsInfo"], user);

                generateAvailableUrls(
                    queryClientWritersPage,
                    LANGUAGES,
                    `/news/tag`,
                );

                return {
                    props: {
                        dehydratedState: dehydrate(queryClientWritersPage),
                    },
                    revalidate: parseInt(
                        process.env.NEXT_PUBLIC_REVALIDATE_TIME as string,
                    ),
                };
            }
            case "Search": {
                const queryClientSearch = new QueryClient(DEFAULT_OPTIONS);
                const { query, req } = ctx as GetServerSidePropsContext;

                await Promise.all([
                    getDomainId(
                        queryClientSearch,
                        getTranslationKeys([
                            {
                                operation: "keyIn",
                                value: [
                                    "several_general_breadcrumb_home",
                                    "several_siteMapPage_SiteMap",
                                    "several_siteMapPage_Vendors",
                                    "several_siteMapPage_Blogs",
                                    "several_siteMapPage_Pages",
                                    "several_general_search_title",
                                    "several_search_breadcrumb_search",
                                    "several_general_you_searched_for",
                                    "several_general_top_matches",
                                    "several_general_load_more_results",
                                    "several_general_meta_description",
                                    "several_searchPage-meta-title-1",
                                    "several_searchPage-meta-title-2",
                                ],
                            },
                        ]),
                    ),
                    queryClientSearch.prefetchInfiniteQuery<OpenSearchResult[]>(
                        {
                            queryKey: [
                                "searchResults.infinite",
                                { query: query.q as string, offset: 0 },
                            ],
                            queryFn: async () => {
                                const { data } = await getSearchResult({
                                    searchQuery: query.q as string,
                                    req: req as NextApiRequest,
                                });
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                                return data ?? [];
                            },
                        },
                    ),
                    // () => {
                    //     return queryClientSearch.fetchInfiniteQuery<
                    //         OpenSearchResult[]
                    //     >({
                    //         queryKey: [
                    //             "searchResults.infinite",
                    //             {
                    //                 offset: 0,
                    //             },
                    //         ],
                    //         queryFn: async () => {
                    //             await getSearchResult({
                    //                 searchQuery: query.q as string,
                    //                 req: req as NextApiRequest,
                    //             });
                    //         },
                    //     });
                    // },
                ]);
                generateAvailableUrls(queryClientSearch, LANGUAGES, `/search`);

                queryClientSearch.setQueryData(
                    [
                        "searchResults.infinite",
                        { query: query.q as string, offset: 0 },
                    ],
                    (data) => ({
                        ...(data as InfiniteData<GQLCategoryPostsQuery>),
                        pageParams: [],
                    }),
                );

                queryClientSearch.setQueryData(["activePage"], "search");
                return {
                    props: {
                        dehydratedState: dehydrate(queryClientSearch),
                    },
                };
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error: ", error);
        return {
            props: {},
        };
    }
};

export default initialCall;
